<template>
	<div class="main">
		<template  v-if="dataList">
		<div v-for="(item,index) in dataList.refundOrderGoodsList" :key="index" class="goods-card-box">
			<div>
				<div class="flex goods-card-goods-item">
					<div class="goods-card-goods-left">
						<van-image width="100" height="100" :src="item.goodsImg"/>
					</div>
					<div class="flex flex-column flex-start goods-card-goods-right">
						<div class="text-weight goods-title">{{item.goodsName}}</div>
						<div class="unit-text-box">
							<span class="unit-text">单位：{{item.goodsUnit}}</span>
							<span>× {{item.goodsNumber}}</span>
						</div>
					</div>
				</div>
				<div class="flex goods-card-title item-line">
					<div>
						<span>仓库数量：</span>
						<span>{{item.depositoryNum}}</span>
					</div>
					<div v-if="item.returnDepositoryNum>0" class="item-line-right-box">
						<span>退货数量：</span>
						<span>{{item.returnDepositoryNum}}</span>
					</div>
				</div>
				<div class="flex goods-card-title item-line">
					<div>
						<span>门店数量：</span>
						<span>{{item.shopNum}}</span>
					</div>
					<div v-if="item.returnShopNum>0" class="item-line-right-box">
						<span>退货数量：</span>
						<span>{{item.returnShopNum}}</span>
					</div>
				</div>
				<div class="flex goods-card-title item-line">
					<div>
						<span>顾客已提数量：</span>
						<span>{{item.pickupNum}}</span>
					</div>
					<div v-if="item.returnPickUpNum>0" class="item-line-right-box">
						<span>退货数量：</span>
						<span>{{item.returnPickUpNum}}</span>
					</div>
				</div>
				<div class="flex goods-card-title item-line" style="font-weight: bold">
					<span>退货合计数量：</span>
					<span>{{item.returnTotalNum}}</span>
				</div>
				<div class="flex goods-card-title item-line" style="font-weight: bold">
					<span>退货合计金额：</span>
					<span>{{item.returnTotalPrice}}</span>
				</div>
			</div>
		</div>
			<div class="order-status">
				<div class="order-status-title">
					<div class="info-box-title-icon"></div>
					<div>退货原因</div>
				</div>
				<div class="return-res-text">{{dataList.returnMsg}}</div>
			</div>
		<div class="order-status">
			<div class="order-status-title">
				<div class="info-box-title-icon"></div>
				<div>审批进度</div>
			</div>
			<div>
				<van-steps direction="vertical" active-color="#2586FF" :active="stepId">
					<van-step v-for="(item, index) in activities" :key="index">
						<h3>{{item.levelName}}</h3>
						<div v-for="(item2, index2) in activities[index].auditorList" :key="index2">
							<div class="steps-info-ok" v-if="item2.checkFlag === 1">
								<div v-if="item2.auditorName !== ''">{{item2.auditorName}} <span style="color: green">已通过</span></div>
								<div v-if="item2.auditorName !== ''">{{item2.createTime}}</div>
							</div>
							<div v-else-if="item2.checkFlag === 2">
								<div class="steps-info-rt">
									<div>{{item2.auditorName}} <span style="color: red">已驳回</span></div>
									<div><van-icon name="cross" /></div>
								</div>
								<div class="no-message">驳回原因：{{item2.message}}</div>
							</div>
							<div v-else class="steps-info-no">
								<div>{{item2.auditorName}}</div>
							</div>
						</div>
					</van-step>
				</van-steps>
			</div>
		</div>
		<div class="margin-box"></div>
		<div v-if="appStatus !== 3 && appStatus !== 4" class="button-box">
			<div @click="toOk" class="button-ok">通过</div>
			<div @click="toShowNo" class="button-no">驳回</div>
		</div>
		<van-popup
				v-model="show"
				closeable
				lock-scroll
				round
				close-icon="close"
				:style="{ height: '200px', width:'80vw' }"
		>
			<div class="message-box-text">
				<van-field
						v-model="addData.applyMsg"
						rows="2"
						autosize
						type="textarea"
						placeholder="请输入驳回原因"
				/>
			</div>
			<div class="message-box-button">
				<div @click="show = false" class="button-cancel">取消</div>
				<div @click="toNo" class="button-ok">确认</div>
			</div>
		</van-popup>
			<van-popup
					v-model="selectCard"
					closeable
					lock-scroll
					round
					close-icon="close"
					:style="{ height: '400px', width:'80vw' }"
			>
				<div class="popup-top-text">审批通过请先选择转账卡</div>
				<div class="message-box-text">
					<selectSearch @change="dropDownClick" :curData="curData" :itemData="itemList"></selectSearch>
				</div>
				<div class="message-box-button">
					<div @click="selectCard = false" class="button-cancel">取消</div>
					<div @click="toPass" class="button-ok">确认</div>
				</div>
			</van-popup>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
	</div>
</template>

<script>
	import { getCardInfo, passReturnOrder, rejectReturnOrder, getApprovalV2, getCardByUser } from '@/api/service'
	import {Dialog, Tag, Toast} from 'vant';
	import { Empty } from 'vant';
	import selectSearch from '@/components/dropdown.vue'
	export default {
	  name: 'DemandList',
	  components: {
		  // Tag,
		  selectSearch,
		  Empty
	  },
	  data() {
	    return {
			orderNo: '', // 单号
			returnNo: '', // 退货单号
			selectCardId: '',
			activities: '', // 流程列表
			dataList: {},
			addData: {
				returnNos: '',
				applyMsg: ''
			},
			rejectRemark: '',
			show: false,
			stepId: '',
			appStatus: 0, // 审批流状态,
			selectCard: false,
			allCardList:'',
			itemList: [], // 卡列表
			curData: ''
	    }},
	  created() {
		  const query = this.$route.query
		  if (!query.orderNo || !query.returnNo) {
			  Dialog.alert({title: '提示', message: '参数错误',})
			  return
		  }
		  this.orderNo = query.orderNo
		  this.returnNo = query.returnNo
		  this.addData.returnNos = [query.returnNo]
		  this.getInfo()
		  this.showApproval()
		  this.getCardList()
	  },
	  mounted:function(){
	  },
	  methods: {
		  dropDownClick(e) {
			  console.log(e)
			  this.selectCardId = this.allCardList[e.index].id
		  },
	  	// 获取信息
		  getInfo(){
		  	const postData = {}
			  postData.orderNo = this.orderNo
			  postData.returnNo = this.returnNo
			  getCardInfo(postData).then((res) => {
				  console.log(res.data)
				  this.dataList = res.data;
				  // this.showApproval()
			  })
		  },
		  // 查看流程
		  showApproval() {
			  const postData = {}
			  postData.id = this.returnNo
			  getApprovalV2(postData).then(response => {
				  console.log(response)
				  this.activities = response.data.list
				  this.appStatus = response.data.status
                  // 判断进行到了哪一步
                  for (let i = 0; i < this.activities.length; i++) {
                  	if(this.activities[i].checkFlag === 1 || this.activities[i].checkFlag === 2){
                  		this.stepId = i
					}
				  }
			  })
		  },
		  // 提交通过
		  toOk(){
		  	let nowStep = ''
			  if (this.stepId === '') {
				  nowStep = 0
			  } else {
				  nowStep = this.stepId + 1
			  }
		  	// 判断是不是出纳审批
			  if(this.activities[nowStep].levelName === '出纳审批'){
				  this.selectCard = true
				  return
			  }
			  Dialog.confirm({
				  title: '是否确认审批通过',
				  message: '审批通过后此审批流将完结，审批结果不能修改，请谨慎操作！',
			  })
			  .then(() => {
				  // on confirm
				  this.toPass()
			  })
			  .catch(() => {
				  // on cancel
			  });
		  },
		  // 展示拒绝
		  toShowNo(){
			  this.show = true
		  },
		  // 提交拒绝
		  toNo(){
			  this.show = false
			  this.toReject()
		  },
		  // 获取卡列表
		  getCardList() {
			  const postData = {}
			  // postData.userId = sessionStorage.getItem("userId");
			  getCardByUser(postData).then((response) => {
				  console.log(response)
				  this.allCardList = response.data
				  for(let i = 0;i < response.data.length; i++){
					  this.itemList.push(response.data[i].cardName)
				  }
			  })
		  },
		  // 通过
		  toPass(){
			  const postData = {}
			  postData.returnNos = [this.returnNo]
			  postData.cardId = this.selectCardId
			  passReturnOrder(postData).then((response) => {
			  	if(response.code === 200 || response.code === 0){
					this.selectCard = false
					this.showApproval()
					Dialog.alert({title: '提示', message: '操作成功',})
				}
			  })
		  },
		  // 驳回
		  toReject() {
		  	if(this.addData.applyMsg === ''){
				Dialog.alert({title: '提示', message: '拒绝理由不能为空',})
				return
			}
			  rejectReturnOrder(this.addData).then((response) => {
				  if(response.code === 200 || response.code === 0){
					  this.showApproval()
					  Dialog.alert({title: '提示', message: '操作成功',})
				  }
			  })
		  },
	  }
	}
</script>

<style scoped>
.order-status ::v-deep .van-step__circle-container{
  top: 37px;

}

.order-status ::v-deep .van-step__line{
  top: 35px;
}

	.main{
		padding: 20px;
		color:#333333;
	}
	.goods-card-box{
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
		margin-bottom: 20px;
	}
	.flex{
		display: flex;
	}
	.flex-column{
		flex-direction: column;
	}
	.flex-start{
		justify-content: flex-start;
	}
	.text-weight{
		font-weight: bold;
	}
	.top-title{
		font-size: 16px;
		font-weight: bold;
		margin: 0 0 20px 0 ;
	}
	.goods-card-title{
		justify-content: space-between;
	}
	.goods-card-goods-list{
	}
	.goods-card-goods-item{
		margin: 15px 0 15px 0;
	}
	.goods-title{
		width: 200px;
		font-size: 18px;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /* 这里是超出几行省略 */
		overflow: hidden;
	}
	.goods-card-goods-left{
		padding-right: 15px;
	}
	.goods-card-goods-right{

	}
	.unit-text{
		height: 28px;
		line-height: 28px;
		background: #F5F6F7;
		border-radius: 4px;
		padding: 0 10px;
		font-size: 16px;
		font-weight: 400;
		color: #999999;
		width:fit-content;
		width:-webkit-fit-content;
	    width:-moz-fit-content;
	}
	.unit-text-box{
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 10px;
	}
	.item-line{
		height: 30px;
		line-height: 30px;
	}
	.order-status{
		margin: 20px 0 0 0;
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.order-status-title{
		display: flex;
		font-weight: bold;
		font-size: 16px;
	}
	.info-box-title-icon{
		width: 5px;
		height: 20px;
		background: #2586FF;
		color: #2586FF;
		margin-right: 10px;
	}
	.button-box{
		width: calc(100vw - 40px);
		position: fixed;
		bottom: 0;
		background-color: #ffffff;
		display: flex;
		justify-content: space-around;
		padding: 20px 0;
	}
	.button-ok{
		background-color: #237FF0;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-no{
		background-color: #FF7833;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-cancel{
		background-color: #abadaf;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.margin-box{
		width: 100%;
		height: 100px;
	}
	.message-box-text{
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	.message-box-button{
		display: flex;
		justify-content: space-around;
		width: 100%;
		margin-top: 30px;
	}
    .steps-info-ok{
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        color: #237FF0;
    }
	.steps-info-rt{
		height: 30px;
		line-height: 30px;
		display: flex;
		justify-content: space-between;
		color: #f83b51;
	}
    .steps-info-no{
        height: 25px;
        line-height: 25px;
    }
	.no-message{
		color: #f83b51;
	}
	.item-line-right-box{}
	.return-res-text{
		padding: 10px 0;
	}
	.popup-top-text{
		width: 100%;
		text-align: center;
		margin-top: 30px;
		font-weight: bold;
	}
</style>
