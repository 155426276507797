<template>
	<div class="main">
		<template  v-if="dataList">
			<div class="top-title">申请人：{{dataList.purchase.operator}}</div>
		<div class="goods-card-box">
			<div class="flex goods-card-title">
				<span>{{dataList.purchase.orderNo}}</span>
				<span>
					<tag v-if="dataList.purchase.status === '21'" color="rgba(247, 75, 75, 0.1)" text-color="#F74B4B" size="large" >{{dataList.purchase.statusMsg}}</tag>
					<tag v-else-if="dataList.purchase.status === '20'" color="rgba((0, 155, 96, 0.1)" text-color="#00A868" size="large" >{{dataList.purchase.statusMsg}}</tag>
					<tag v-else color="rgba(35, 127, 240, 0.1)" text-color="#237FF0" size="large" >{{dataList.purchase.statusMsg}}</tag>
				</span>
			</div>
			<div v-for="(goods, key) in dataList.purchaseGoodsList" :key="key" class="flex flex-column flex-start goods-card-goods-list">
				<div class="flex goods-card-goods-item">
					<div class="goods-card-goods-left"><van-image width="100" height="100" :src="goods.goodsImg"/></div>
					<div class="flex flex-column flex-start goods-card-goods-right">
						<div class="text-weight goods-title">{{goods.goodsName}}</div>
						<div class="unit-text">单位：{{goods.goodsUnit}}</div>
						<div class="price-text">{{goods.purchasePrice}}元</div>
					</div>
				</div>
				<div class="flex goods-card-title item-line">
					<span>采购数量</span>
					<span>{{goods.goodsNum}}</span>
				</div>
				<div class="flex goods-card-title item-line">
					<span>运费</span>
					<span>{{goods.postFee}}</span>
				</div>
				<div class="flex goods-card-title item-line">
					<span>单品总价</span>
					<span>{{goods.goodsTotalPrice}}</span>
				</div>
				<div class="flex goods-card-title item-line">
					<span>供应商</span>
					<span>{{goods.supplierName}}</span>
				</div>
				<div class="flex goods-card-title item-line">
					<span>账号</span>
					<span>{{goods.supplierAccound}}</span>
				</div>
				<van-divider dashed></van-divider>
			</div>
		</div>
		<div class="order-status">
			<div>
				<div class="ticket-title">发票</div>
				<div v-if="dataList.purchase.invoiceUrl">
					<a :href="dataList.purchase.invoiceUrl" target="_blank">
						<van-image v-for="item in dataList.purchase.payVoucherArr" :key="item" width="100" height="100" :src="item" style="margin-right: 5px"/>
					</a>
				</div>
				<div class="no-text" v-else>无</div>
			</div>
			<div>
				<div class="ticket-title">付款凭证</div>
				<div v-if="dataList.purchase.payVoucher">
					<a :href="dataList.purchase.payVoucher" target="_blank">
						<van-image v-for="item in dataList.purchase.invoiceUrlArr" :key="item" width="100" height="100" :src="item" style="margin-right: 5px"/>
					</a>
				</div>
				<div class="no-text" v-else><van-uploader v-model="ticket" :max-count="3" :after-read="afterRead" /></div>
			</div>
		</div>
		<div class="order-status">
			<div class="order-status-title">审批信息</div>
			<div>
				<van-steps direction="vertical" active-color="#2586FF" :active="activities.length-1">
					<van-step v-for="(item, index) in activities" :key="index">
						<h3>{{item.message}}</h3>
						<p>操作人：{{item.operator}}</p>
						<p>{{item.createTime}}</p>
					</van-step>
				</van-steps>
			</div>
		</div>
		<div class="margin-box"></div>
		<div v-if="dataList.purchase.status !== 20 && dataList.purchase.status !== 21" class="button-box">
			<div @click="toOk" class="button-ok">通过</div>
			<div @click="toShowNo" class="button-no">驳回</div>
		</div>
		<van-popup
				v-model="show"
				closeable
				lock-scroll
				round
				close-icon="close"
				:style="{ height: '200px', width:'80vw' }"
		>
			<div class="message-box-text">
				<van-field
						v-model="addData.rejectMsg"
						rows="2"
						autosize
						type="textarea"
						placeholder="请输入驳回原因"
				/>
			</div>
			<div class="message-box-button">
				<div @click="show = false" class="button-cancel">取消</div>
				<div @click="toNo" class="button-ok">确认</div>
			</div>
		</van-popup>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
	</div>
</template>

<script>
	import { getPurchaseInfo, getApproval, getCosToken, purchaseApprove } from '@/api/service'
	import {Dialog, Tag, Toast} from 'vant';
	import COS from 'cos-js-sdk-v5'
	import { Empty } from 'vant';
	export default {
	  name: 'DemandList',
	  components: {
		  Tag,
		  Empty
	  },
	  data() {
	    return {
			purchaseNo: '', // 单号
			activities: '', // 流程列表
			dataList: {
				purchase:{
					orderNo:''
				},
				purchaseGoodsList:{

				}
			},
			addData: {
				purchaseNo: '',
				ispass: '',
				rejectMsg: '',
				payVoucher: []
			},
			ticket:[],
			show: false,
			cos: {}
	    }},
	  created() {
		  const query = this.$route.query
		  if (!query.purchaseNo) {
			  Dialog.alert({title: '提示', message: '参数错误',})
			  return
		  }
		  this.purchaseNo = query.purchaseNo
		  this.addData.purchaseNo = query.purchaseNo
		  this.getInfo()
		  this.showApproval()
		  this.initCOS()
	  },
	  mounted:function(){
	  },
	  methods: {
	  	// 获取信息
		  getInfo(){
		  	const postData = {}
			  postData.purchaseNo = this.purchaseNo
			  getPurchaseInfo(postData).then((res) => {
				  console.log(res.data)
				  this.dataList = res.data;
				  console.log(this.dataList.purchase.orderNo)
			  })
		  },
		  // 查看流程
		  showApproval() {
			  const postData = {}
			  postData.id = this.purchaseNo
			  getApproval(postData).then(response => {
				  console.log(response)
				  this.activities = response.data
			  })
		  },
		  // 提交通过
		  toOk(){
			  Dialog.confirm({
				  title: '通过',
				  message: '是否确认通过',
			  })
			  .then(() => {
				  // on confirm
				  this.saveData(1)
			  })
			  .catch(() => {
				  // on cancel
			  });
		  },
		  // 展示拒绝
		  toShowNo(){
			  this.show = true
		  },
		  // 提交拒绝
		  toNo(){
			  this.saveData(0)
		  },
		  // 提交保存
		  saveData(type) {
			  const tempFile = []
			  for (let i = 0; i < this.ticket.length; i++) {
				  tempFile.push(this.ticket[i].url)
			  }
			  // this.addData.payVoucher = tempFile.join(',')
			  if (tempFile.length > 0) {
				  this.addData.payVoucher = JSON.stringify(tempFile)
			  }
			  this.addData.ispass = type
			  purchaseApprove(this.addData).then((response) => {
			  	console.log(response)
			  })
		  },
		  // 监听发票上传
		  afterRead(file) {
			  console.log(file);
			  const typeArr = ['image/png', 'image/jpeg', 'image/jpg']
			  const isJPG = typeArr.indexOf(file.file.type) !== -1
			  if (!isJPG) {
				  return
			  }
			  // 将文件上传至服务器
			  this.toUpload(1, file)
		  },
		  // 初始化存储桶
		  initCOS() {
			  console.log(111)
			  // 初始化实例
			  const _this = this
			  _this.cos = new COS({
				  // 必选参数
				  getAuthorization: function(options, callback) {
					  console.log(222)
					  getCosToken({}).then(response => {
						  console.log(response)
						  if (response.code === 200 || response.code === 0) {
							  //  console.log(json)
							  const credentials = response.data
							  callback({
								  TmpSecretId: credentials.tmpSecretId,
								  TmpSecretKey: credentials.tmpSecretKey,
								  XCosSecurityToken: credentials.sessionToken,
								  // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
								  StartTime: credentials.startTime, // 时间戳，单位秒，如：1580000000
								  ExpiredTime: credentials.expiredTime // 时间戳，单位秒，如：1580000900
								  // ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
							  })
						  }
					  })
				  }
			  })
		  },
		  // 获取随机数
		  GetRandomNum(Min,Max) {
			  var Range = Max - Min;
			  var Rand = Math.random();
			  return(Min + Math.round(Rand * Range));
		  },
		  // 提交上传
		  toUpload(type, file) {
			  const _this = this
			  Toast.loading({
				  message: '加载中...',
				  forbidClick: true,
				  duration: 0
			  });
			  this.cos.putObject({
				  Bucket: 'tousubiaoyang-1301970825', /* 填入您自己的存储桶，必须字段 */
				  Region: 'ap-nanjing', /* 存储桶所在地域，例如ap-beijing，必须字段 */
				  Key: 'chartVideo/' + new Date().getTime() + this.GetRandomNum(10000,99999), /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
				  StorageClass: 'STANDARD',
				  Body: file.file, // 上传文件对象
				  onProgress: function(progressData) {
					  console.log(JSON.stringify(progressData))
				  }
			  }, function(err, data) {
				  Toast.clear()
				  console.log(err || data)
				  let newUrl = data.Location.replace(/tousubiaoyang-1301970825.cos.ap-nanjing.myqcloud.com/, 'tousubiaoyang-1301970825.file.myqcloud.com')
				  newUrl = 'https://' + newUrl
				  _this.addData.payVoucher.push(newUrl)
				  // const tempUrl = {}
				  // tempUrl.url = newUrl
				  // tempUrl.isImage = true
				  // _this.ticket.push(tempUrl)
				  _this.ticket[_this.ticket.length -1].url = newUrl
				  console.log(_this.ticket)
			  })
		  }
	  }
	}
</script>

<style scoped>
	.main{
		padding: 20px;
		color:#333333;
	}
	.goods-card-box{
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.flex{
		display: flex;
	}
	.flex-column{
		flex-direction: column;
	}
	.flex-start{
		justify-content: flex-start;
	}
	.text-weight{
		font-weight: bold;
	}
	.top-title{
		font-size: 16px;
		font-weight: bold;
		margin: 0 0 20px 0 ;
	}
	.goods-card-title{
		justify-content: space-between;
	}
	.goods-card-goods-list{
	}
	.goods-card-goods-item{
		margin: 15px 0 15px 0;
	}
	.goods-title{
		width: 200px;
		font-size: 18px;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; /* 这里是超出几行省略 */
		overflow: hidden;
	}
	.goods-card-goods-left{
		padding-right: 15px;
	}
	.goods-card-goods-right{
	}
	.unit-text{
		height: 28px;
		line-height: 28px;
		background: #F5F6F7;
		border-radius: 4px;
		padding: 0 10px;
		font-size: 16px;
		font-weight: 400;
		color: #999999;
		width:fit-content;
		width:-webkit-fit-content;
	    width:-moz-fit-content;
		margin-top: 10px;
	}
	.item-line{
		height: 30px;
		line-height: 30px;
	}
	.order-status{
		margin: 20px 0 0 0;
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.order-status-title{
		font-weight: bold;
		font-size: 16px;
	}
	.button-box{
		width: calc(100vw - 40px);
		position: fixed;
		bottom: 0;
		background-color: #ffffff;
		display: flex;
		justify-content: space-around;
		padding: 20px 0;
	}
	.button-ok{
		background-color: #237FF0;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-no{
		background-color: #FF7833;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-cancel{
		background-color: #abadaf;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.margin-box{
		width: 100%;
		height: 100px;
	}
	.message-box-text{
		margin-top: 40px;
	}
	.message-box-button{
		display: flex;
		justify-content: space-around;
		width: 100%;
		margin-top: 38px;
	}
	.ticket-title{
		font-weight: bold;
		margin-bottom: 10px;
	}
	.no-text{
		margin-bottom: 10px;
	}
	.price-text{
		color: #FF7833;
		margin-top: 20px;
	}
</style>
