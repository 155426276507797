import request from '@/utils/request'
// const baseUrl = 'http://salary.renkangshangcheng.cn'
// 根据code获取用户id
export function authByCode(data) {
  return request({
    url: '/login/getUserInfo',
    method: 'get',
    params: data,
  })
}

export function loginByUserid(data) {
  return request({
    url: '/login/login',
    method: 'post',
    params: data,
  })
}


