<template>
	<popup v-model="show" position="bottom" :close-on-click-overlay="false">
		<Area title="选择城市" :area-list="areaList" :columns-num="2" @confirm="handleConfirm" @cancel="handleCancel" />
	</popup>
</template>

<script>
	import { Popup } from 'vant';
	import { Area } from 'vant';
	import { areaList } from '@vant/area-data';
	export default {
		name: 'myCity',
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		components: {
			Popup,
			Area
		},
		data() {
			return {
				areaList
			}
		},
		methods: {
			handleConfirm(res) {
				this.$emit("confirm", res);
			},
			handleCancel() {
				this.$emit('cancel');
			}
		}
	}
</script>

<style>
</style>
