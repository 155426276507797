<template>
  <div class="main">
    <div class="header-text">
      分账业务：该业务是指将采购商品价差利润部分，通过分账功能，将资金划分给各个加盟商平台或者总部服务公司。
    </div>
    <div class="info-box">
      <div class="info-item">分账方：{{ stepTwoInfo.platformName }}</div>
      <div class="info-item">
        订单日期：{{ stepTwoInfo.startTimeString }}——{{
          stepTwoInfo.startTimeString
        }}
      </div>
      <div class="info-item" style="padding-bottom: 0">
        订单分账金额：{{ (stepTwoInfo.totalMoney / 100).toFixed(2) }}元
      </div>
    </div>
    <div class="table-view">
      <div class="order-status-title">
        <div class="info-box-title-icon"></div>
        <div>资金分账公司</div>
      </div>
      <div class="table-list" style="border: 1px solid #c2deff;border-bottom: none;">
        <div class="table-list-header">
          <div
            class="table-list-header-item"
            style="
              width: 40%;
              border-right: 1px solid #c2deff;
              padding: 11px 0 11px 6px;
              box-sizing: border-box;
            "
          >
            公司名称
          </div>
          <div
            class="table-list-header-item"
            style="
              width: 35%;
              border-right: 1px solid #c2deff;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            所属平台
          </div>
          <div
            class="table-list-header-item"
            style="
              width: 25%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            金额(元)
          </div>
        </div>
        
        <div
          class="table-list-header"
          style="border-bottom: 1px solid #c2deff;background-color: #fff"
          v-for="(item, index) in stepTwoInfo.allocateDetailList"
          :key="index"
        >
          <div
            class="table-list-header-item"
            style="
              width: 40%;
              border-right: 1px solid #c2deff;
              padding: 11px 0 11px 6px;
              box-sizing: border-box;
            "
          >
            {{ item.companyName }}
          </div>
          <div
            class="table-list-header-item"
            style="
              width: 35%;
              border-right: 1px solid #c2deff;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            {{ item.platformName }}
          </div>
          <div
            class="table-list-header-item"
            style="
              width: 25%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            {{ (item.money / 100).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
    <div class="gray-line"></div>
    <div class="order-status">
      <div class="order-status-title">
        <div class="info-box-title-icon"></div>
        <div>审批进度</div>
      </div>
      <div>
        <van-steps direction="vertical" active-color="#2586FF" :active="stepId">
          <van-step v-for="(item, index) in activities" :key="index">
            <h3>{{ item.levelName }}</h3>
            <div
              v-for="(item2, index2) in activities[index].auditorList"
              :key="index2"
            >
              <div class="steps-info-ok" v-if="item2.checkFlag === 1">
                <div v-if="item2.auditorName !== ''">
                  {{ item2.auditorName }}
                  <span style="color: green">已通过</span>
                </div>
                <div v-if="item2.auditorName !== ''">
                  {{ item2.createTime }}
                </div>
              </div>
              <div v-else-if="item2.checkFlag === 2">
                <div class="steps-info-rt">
                  <div>
                    {{ item2.auditorName }}
                    <span style="color: red">已驳回</span>
                  </div>
                  <div><van-icon name="cross" /></div>
                </div>
                <div class="no-message">{{ item2.message }}</div>
              </div>
              <div v-else class="steps-info-no">
                <div>{{ item2.auditorName }}</div>
              </div>
            </div>
          </van-step>
        </van-steps>
      </div>
    </div>
    <div style="width: 100%;height: 66px;"> </div>
    <div class="btn-view"  v-if="appStatus !== 3 && appStatus !== 4">
      <div class="cancelBtn" @click="toShowNo">驳回</div>
      <div class="comifmBtn" @click="toOk">同意</div>
    </div>
    <van-popup
				v-model="show"
				closeable
				lock-scroll
				round
				close-icon="close"
				:style="{ height: '200px', width:'80vw' }"
		>
			<div class="message-box-text">
				<van-field
						v-model="addData.reason"
						rows="2"
						autosize
						type="textarea"
						placeholder="请输入驳回原因"
				/>
			</div>
			<div class="message-box-button">
				<div @click="show = false" class="button-cancel">取消</div>
				<div @click="toNo" class="button-ok">确认</div>
			</div>
		</van-popup>
  </div>
</template>

<script>
import {
  getCardInfo,
  passReturnOrder,
  rejectReturnOrder,
  getApprovalV2,
  getCardByUser,
  getAccountInfo,
  submitApproval,
  approvalRefuse
} from "@/api/service";
import { Dialog, Tag, Toast } from "vant";
import { Empty } from "vant";
import selectSearch from "@/components/dropdown.vue";
export default {
  name: "DemandList",
  components: {
    // Tag,
  },
  data() {
    return {
      orderNo: "", // 单号
      returnNo: "", // 退货单号
      selectCardId: "",
      activities: "", // 流程列表
      dataList: {},
      addData: {
        allocateStepTwoNo: "",
        reason: "",
      },
      rejectRemark: "",
      show: false,
      stepId: "",
      appStatus: 0, // 审批流状态,
      selectCard: false,
      allCardList: "",
      itemList: [], // 卡列表
      curData: "",
      allocateStepTwoNo: "", //分账id
      stepTwoInfo: {}, // 分账信息
    };
  },
  created() {
    const query = this.$route.query;
    if (!query.allotNo) {
      Dialog.alert({ title: "提示", message: "参数错误" });
      return;
    }
    this.allocateStepTwoNo = query.allotNo;
    this.getInfo();
    this.showApproval();
    this.getCardList();
  },
  mounted: function () {},
  methods: {
    dropDownClick(e) {
      console.log(e);
      this.selectCardId = this.allCardList[e.index].id;
    },
    // 获取信息
    getInfo() {
      const postData = {};
      postData.allocateStepTwoNo = this.allocateStepTwoNo;
      getAccountInfo(postData).then((res) => {
        console.log(res.data);
        this.stepTwoInfo = res.data;
        // this.showApproval()
      });
    },
    // 查看流程
    showApproval() {
      const postData = {};
      postData.id = this.allocateStepTwoNo;
      getApprovalV2(postData).then((response) => {
        console.log(response);
        this.activities = response.data.list;
        this.appStatus = response.data.status;
        // 判断进行到了哪一步
        for (let i = 0; i < this.activities.length; i++) {
          if (
            this.activities[i].checkFlag === 1 ||
            this.activities[i].checkFlag === 2
          ) {
            this.stepId = i;
          }
        }
      });
    },
    // 提交通过
    toOk() {
      let nowStep = "";
      if (this.stepId === "") {
        nowStep = 0;
      } else {
        nowStep = this.stepId + 1;
      }
      // 判断是不是出纳审批
      if (this.activities[nowStep].levelName === "出纳审批") {
        this.selectCard = true;
        return;
      }
      Dialog.confirm({
        title: "是否确认审批通过",
        message: "审批通过后此审批流将完结，审批结果不能修改，请谨慎操作！",
      })
        .then(() => {
          // on confirm
          this.toPass();
        })
        .catch(() => {
          // on cancel
        });
    },
    // 展示拒绝
    toShowNo() {
      this.show = true;
    },
    // 提交拒绝
    toNo() {
      this.show = false;
      this.toReject();
    },
    // 获取卡列表
    getCardList() {
      const postData = {};
      // postData.userId = sessionStorage.getItem("userId");
      getCardByUser(postData).then((response) => {
        console.log(response);
        this.allCardList = response.data;
        for (let i = 0; i < response.data.length; i++) {
          this.itemList.push(response.data[i].cardName);
        }
      });
    },
    // 通过
    toPass() {
      const postData = {};
      postData.allocateStepTwoNo = this.allocateStepTwoNo;
      submitApproval(postData).then((response) => {
        console.log(response,'1');
        if (response.code === 200 || response.code === 0) {
          this.selectCard = false;
          Dialog.alert({ title: "提示", message: "操作成功" });
        }
        this.showApproval();

      });
    },
    // 驳回
    toReject() {
      this.addData.allocateStepTwoNo = this.allocateStepTwoNo
      if (this.addData.reason === "") {
        Dialog.alert({ title: "提示", message: "拒绝理由不能为空" });
        return;
      }
      approvalRefuse(this.addData).then((response) => {
        console.log(response,'2');

        if (response.code === 200 || response.code === 0) {
          Dialog.alert({ title: "提示", message: "操作成功" });
          this.showApproval();
          
        }else{
        this.showApproval();

        }
        this.showApproval();


      });
    },
  },
};
</script>

<style scoped>
.order-status ::v-deep .van-step__circle-container{
  top: 37px;

}
.order-status ::v-deep .van-step__line{
  top: 35px;
}
html,
body {
  background: #fff;
  width: 100%;
  height: 100%;
}
.main {
  height: 100vh;
  background: #fff;
}
.goods-card-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-start {
  justify-content: flex-start;
}
.text-weight {
  font-weight: bold;
}
.top-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px 0;
}
.goods-card-title {
  justify-content: space-between;
}
.goods-card-goods-list {
}
.goods-card-goods-item {
  margin: 15px 0 15px 0;
}
.goods-title {
  width: 200px;
  font-size: 18px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}
.goods-card-goods-left {
  padding-right: 15px;
}
.goods-card-goods-right {
}
.unit-text {
  height: 28px;
  line-height: 28px;
  background: #f5f6f7;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
}
.unit-text-box {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.item-line {
  height: 30px;
  line-height: 30px;
}
.order-status {
  margin: 17px 0 0 0;
  background: #ffffff;
  border-radius: 8px;
  padding: 0 15px;
  box-sizing: border-box;
}
.order-status-title {
  display: flex;
  font-weight: bold;
  font-size: 17px;
  font-weight: 600;
  color: #333333;
  text-align: left;
  font-style: normal;
}
.info-box-title-icon {
  width: 5px;
  height: 20px;
  background: #2586ff;
  color: #2586ff;
  margin-right: 10px;
}
.button-box {
  width: calc(100vw - 40px);
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}
.button-ok {
  background-color: #237ff0;
  color: #ffffff;
  border-radius: 5px;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
}
.button-no {
  background-color: #ff7833;
  color: #ffffff;
  border-radius: 5px;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
}
.button-cancel {
  background-color: #abadaf;
  color: #ffffff;
  border-radius: 5px;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
}
.margin-box {
  width: 100%;
  height: 100px;
}
.message-box-text {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.message-box-button {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
}
.steps-info-ok {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  color: #237ff0;
}
.steps-info-rt {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  color: #f83b51;
}
.steps-info-no {
  height: 25px;
  line-height: 25px;
}
.no-message {
  color: #f83b51;
}
.item-line-right-box {
}
.return-res-text {
  padding: 10px 0;
}
.popup-top-text {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
}
.header-text {
  padding: 5px 10px;
  box-sizing: border-box;
  background: #fff0ea;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #f33f02;
  text-align: left;
  font-style: normal;
}

.info-box {
  padding: 12px 15px;
  box-sizing: border-box;
}
.info-item {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  text-align: left;
  font-style: normal;
  margin-bottom: 12px;
}

.table-view {
  padding: 0 15px;
  box-sizing: border-box;
}
.table-list {
  width: 100%;
  margin-top: 12px;
}
.table-list-header {
  width: 100%;
  display: flex;
  border-radius: 4px 4px 0 0;
  background: #d5e8ff;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  font-style: normal;
  border-bottom: 1px solid #c2deff;
}
.gray-line {
  width: 100%;
  height: 12px;
  background: #f3f5f8;
  margin-top: 12px;
}
.btn-view {
  width: 100%;
  padding: 10px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 100%),
    #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: 0;
  left: 0;
}
.cancelBtn{
  width: 140px;
height: 44px;
text-align: center;
line-height: 44px;
border: 1px solid #2580F0;
font-weight: 400;
color: #2580F0;
font-style: normal;
text-transform: none;
border-radius: 4px;
font-size: 17px;

}

.comifmBtn{
  width:191px;
height: 44px;
background: #2580F0;
border-radius: 4px;
text-align: center;
line-height: 44px;
font-weight: 400;
font-size: 17px;
color: #FFFFFF;
font-style: normal;
text-transform: none;
}
</style>
