import axios from 'axios'
import {IS_DEBUG} from '/env'
import {
	Dialog
} from "vant";
import qs from 'qs';
// create an axios instance
var URL = ''
if(IS_DEBUG) {
	URL = 'https://teststate.renruikeji.cn/api/erp/'
} else {
	URL = 'https://state.renruikeji.cn/api/erp/'
}
const service = axios.create({
	baseURL: URL, // url = base url + request url
	// baseURL: 'https://teststate.renruikeji.cn/api/erp/',
	timeout: 15000, // request timeout
	headers: {
		"Accept": "*/*",
		'Content-Type': 'application/x-www-form-urlencoded'
	}
})

// request interceptor
service.interceptors.request.use(
	config => {
		if (config.dataType != undefined && config.dataType == 'json') {
			config.headers = {
				'Content-Type': 'application/json'
			}
		} else {

			config.data = qs.stringify(config.data)
		}
		if (sessionStorage.getItem("token") != undefined) {
			config.headers.token = sessionStorage.getItem("token");
		}
		return config
	},
	error => {
		// do something with request error
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data

		// if the custom code is not 20000, it is judged as an error.
		if (res.code !== 200 && res.code !== 0) {
			Dialog.alert({
				title: '提示',
				message: res.message,
			}).then(() => {
				// on close
			});
		} else {
			return res
		}
	},
	error => {
		console.log('err' + error) // for debug
		return Promise.reject(error)
	}
)

export default service
