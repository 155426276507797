<template>
	<div class="mainCentent">
		<div class="txt1">申请人：{{userName}}</div>
		
		<PurchaseList :list="list" 
			@updateCity="handleUpdateCity"
			@updateBuyNum="handleUpdateBuyNum"
			@delItem="handleDelItem"
			></PurchaseList>
		
		<div class="order-card flex fcolumn addbox">
			<div class="flex frow fbetween">
				<span>总采购数量</span>
				<span>{{totalBuyNum}}</span>
			</div>
			<div class="addbox-btn">
				<van-button color="#E9F2FE" class="bot-btn" :style="{color:'#237FF0'}" @click="handleAddOne">添加一条</van-button>
			</div>
		</div>
		<div style="height:90px;"></div>
		<div class="flex frow faround bottom">
			<div style="width:48%">
				<van-button color="#E9F2FE" class="bot-btn" :style="{color:'#237FF0'}">取消</van-button>
			</div>
			<div style="width:48%">
				<van-button color="#237FF0" class="bot-btn" @click="handleSubmitConfirm">确定</van-button>
			</div>
		</div>
	</div>
</template>

<script>
	import PurchaseList from '@/views/purchase/component/PurchaseList.vue'
	import {getDemandInfoByDemandNo, saveDemand} from '@/api/purchase'
	import { Dialog } from 'vant';
	export default {
	  name: 'purchase',
	  components: {
		  PurchaseList
	  },
	  data() {
	    return {
			userName:"",
	      userId: '', 	// 用户id
		  demandNo: '', //需求单号
		  list:[],		//需求商品列表
		  demandInfo: {
			"demandNo": "",
			  "privinceName": "",
			  "cityName": "",
			  "totalBuyNum": 0,
			  "demandGoodsList": [
				{
				  "goodsNo": 0,
				  "goodsName": "",
				  "goodsUnit": "",
				  "goodsImg": "",
				  "buyNum": 0
				}
			  ]
		  }, // 单个的需求对象
	    }},
		computed:{
			//计算属性：总裁购书
			totalBuyNum(){
				let num = 0;
				if (this.list.length > 0){
					
					for(let i = 0; i<this.list.length; i++){
						
						for(let j = 0; j < this.list[i].demandGoodsList.length; j++){
							num = parseInt(num) + parseInt(this.list[i].demandGoodsList[j].buyNum)
						}
					}
				}
				return num;
			}
		},
	  created() {
	  },
	  mounted:function(){
		  this.userName = sessionStorage.getItem("username");
		  if (this.$route.query.demandNo == undefined || this.$route.query.demandNo == ""){
			  
			  this.$dialog.alert({
			    title: '提示',
			    message: "需求单不存在",
			  })
		  } else {
			this.demandNo = this.$route.query.demandNo;
			  
			this.getDemandInfo()
		  }
	  },
	  methods: {
		  getDemandInfo(){
			  
			  getDemandInfoByDemandNo({demandNo:this.demandNo}).then((res)=>{
				  
				  this.demandInfo = res.data;
				  this.$set(this.list, this.list.length, res.data)
			  })
			  
		  },
		  //添加一条需求事件
		  handleAddOne(){
			  if (this.list.length >= 20){
				  this.$dialog.alert({
					title: '提示',
					message: "最多可添加20条采购单",
				  })
				  return;
			  }
			  let info = this.demandInfo;
			  info.cityName = "";
			  info.privinceName = "";
			  info.totalBuyNum = 0;
			  
			  this.list.push(info);
		  },
		  // 列表选择城市事件
		  handleUpdateCity(data){
			  let item = JSON.parse(JSON.stringify(this.list[data.index]));
			  item.privinceName = data.data[0].name;
			  item.cityName = data.data[1].name;
			  this.list.splice(data.index, 1, item);
		  },
		  // 加减采购数量
		  handleUpdateBuyNum(data){
			  
			  let listItem = JSON.parse(JSON.stringify(this.list[data.index]))
			  let goods = listItem.demandGoodsList[data.goodsIndex];
			  
			  let num =  goods.buyNum;
			  if (data.type == "-"){
				  if (num <= 0){
					  this.$dialog.alert({
						title: '提示',
						message: "采购数量不能小于0",
					  })
					  return;
				  }
				  num--;
				  goods.buyNum--;
				  // this.list[data.index].demandGoodsList.splice(data.goodsIndex, 1, goods);
			  } else {
				  num++;
				 goods.buyNum++;
			  }
			 
			  this.$set(listItem, listItem.demandGoodsList[data.goodsIndex], goods)
				
			  this.$set(this.list, data.index, listItem)
		  },
		  // 删除商品
		  handleDelItem(data){
			  if (this.list.length == 1){
				  this.$dialog.alert({
					title: '提示',
					message: "采购单数量不能少于1",
				  })
				  return;
			  }
			  this.list.splice(data.index, 1)
		  },
		  // 提交前确认弹层
		  handleSubmitConfirm(){
			 Dialog.confirm({
				 confirmButtonColor:"#237FF0",
				 cancelButtonColor: "#333333",
			     message: '确认提交吗？提交后不可修改',
			 })
			  .then(() => {
			     // on confirm、
				 this.handleSubmit();
			  })
			  .catch(() => {
			     // on cancel
				 console.log("取消")
			  });
		  },
		  // 确认提交
		  handleSubmit(){
			  if (this.list.length < 1){
				  this.$dialog({message:"需求单错误"})
			  }
			  
			  for(let i = 0; i<this.list.length; i++){
				  if (this.list[i].cityName == ""){
					  this.$dialog({
						  title:"提示",
						  message:"城市不能为空"
					  })
					  return ;
				  }
			  }
			  console.log(this.list)
			  saveDemand(this.list).then((res)=>{
				  console.log(res)
				  this.$dialog({
					  title:"提示",
					  message:res.message
				  })
			  })
		  }
	  }
	}
</script>

<style>
	
	.txt1{
		color: rgba(51, 51, 51, 1);
		font-size: 28px;
	}
	.order-card{
		margin-top: 15px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 10px;
	}
	
	.bottom{
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 100px;
		background-color: #ffffff;
		margin:0 -20px;
	}
	.bot-btn{
		width:100%;
		border-radius: 12px;
		height: 60px;
		font-size: 22px;
	}
	.addbox{
		padding: 20px 15px;
		font-size: 24px;
		color:#333333;
		margin-bottom: 10px;
	}
	.addbox-btn{
		margin-top:30px;
	}
</style>