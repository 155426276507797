<template>
	<div class="main">
		<template  v-if="allInfo">
			<!-- 换货顾客 -->
			<div class="member-main">
				<!-- 标题 -->
				<div class="member-title">
					<div class="title-icon"></div>
					<div class="title-text">换货顾客</div>
				</div>
				<!-- 内容 -->
				<div class="member-box">
					<div class="member-left-box">
						<van-image width="40" height="40" class="member-header" :src="allInfo.customerAvatar"/>
						<div class="member-info-box">
							<div class="member-name">{{allInfo.customerName}}</div>
							<div class="member-tel">{{allInfo.customerPhone}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 换货商品 -->
			<div class="exchange-box">
				<div class="item-title">换货订单号：{{allInfo.exchangeNo}}</div>
				<div class="line-box">
					<div class="line-title">销售订单号</div>
					<div class="line-info">{{allInfo.orderNo}}</div>
				</div>
				<div class="line-box">
					<div class="line-title">录入订单时间</div>
					<div class="line-info">{{allInfo.createTime}}</div>
				</div>
				<div v-for="(item,index) in allInfo.exchangeSwapinList" :key="index" class="good">
					<div class="good-content">
						<van-image class="good-img" :src="item.goodsImg"/>
						<div class="good-info">
							<div class="good-title">{{item.goodsName}}</div>
							<div class="good-price">
								<div class="good-unit">单位：{{item.goodsUnit}}</div>
								<div class="good-price-value">销售单价：¥ {{item.salePrice}}</div>
							</div>
						</div>
					</div>
					<div class="line-box">
						<div class="line-title">商品购买数量：{{item.goodsNumber}}</div>
						<div class="line-title">实际售价：￥{{item.paidinPrice}}</div>
					</div>
				</div>
				<!-- 实收金额 -->
				<div class="price-all">实收金额：￥{{allInfo.exchangeSwapinList[0].swapinPrice}}</div>
			</div>
			<!-- 所属类型 -->
			<div class="exchange-box">
				<div class="item-title">换货商品所属类型</div>
				<div v-if="allInfo.exchangeSwapinList[0].depositoryNum>0" class="line-box">
					<div class="line-info">仓库寄存数量：</div>
					<div class="line-info">换货数量 {{allInfo.exchangeSwapinList[0].depositoryNum}}</div>
				</div>
				<div v-if="allInfo.exchangeSwapinList[0].shopNum>0" class="line-box">
					<div class="line-info">门店寄存数量：</div>
					<div class="line-info">换货数量 {{allInfo.exchangeSwapinList[0].shopNum}}</div>
				</div>
				<div v-if="allInfo.exchangeSwapinList[0].pickUpNum>0" class="line-box">
					<div class="line-info">已提货数量：</div>
					<div class="line-info">换货数量 {{allInfo.exchangeSwapinList[0].pickUpNum}}</div>
				</div>
			</div>
			<!-- 金额详情 -->
			<div class="exchange-box">
				<div class="line-box">
					<div class="line-title">总计换货数量：</div>
					<div class="line-info">{{allInfo.exchangeSwapinList[0].goodsNumber}}</div>
				</div>
				<div class="line-box">
					<div class="line-title">实际单价：</div>
					<div class="line-info">{{allInfo.exchangeSwapinList[0].paidinPrice}}</div>
				</div>
				<div class="line-box">
					<div class="line-title">换货总价值：</div>
					<div class="line-info">{{allInfo.exchangeSwapinList[0].swapinPrice}}</div>
				</div>
			</div>
			<!-- 目标换货商品 -->
			<div class="exchange-box">
				<div class="item-title">目标换货商品</div>
				<div v-for="(item,index) in allInfo.exchangeSwapoutList" :key="index">
					<div class="good">
						<div class="good-content">
							<van-image class="good-img" :src="item.goodsImg"/>
							<div class="good-info">
								<div class="good-title">{{item.goodsName}}</div>
								<div class="good-price">
									<div class="good-unit">单位：{{item.goodsUnit}}</div>
									<div class="good-price-value">销售单价：¥ {{item.salePrice}}</div>
								</div>
							</div>
						</div>
						<div class="line-box">
							<div class="line-title">换货数量：{{item.pickUpNum}}</div>
							<div class="line-title">商品实际售价：￥{{item.paidinPrice}}</div>
						</div>
					</div>
					<!-- 实收金额 -->
					<div class="price-all">小计金额：￥{{item.swapinPrice}}</div>
				</div>
			</div>
			<!-- 总计金额 -->
			<!-- 总计 -->
			<div class="all-price-box">
				<div class="title">总计金额：</div>
				<div class="price">￥ {{allInfo.swapoutPrice}}</div>
			</div>
		<!--退货原因-->
		<div class="order-status">
			<div class="order-status-title">
				<div class="info-box-title-icon"></div>
				<div>换货原因</div>
			</div>
			<div class="return-res-text">{{allInfo.exchangeMsg || '-'}}</div>
		</div>
		<div class="order-status">
			<div class="order-status-title">
				<div class="info-box-title-icon"></div>
				<div>审批进度</div>
			</div>
			<div>
				<van-steps direction="vertical" active-color="#2586FF" :active="stepId">
					<van-step v-for="(item, index) in activities" :key="index">
						<h3>{{item.levelName}}</h3>
						<div v-for="(item2, index2) in activities[index].auditorList" :key="index2">
							<div class="steps-info-ok" v-if="item2.checkFlag === 1">
								<div v-if="item2.auditorName !== ''">{{item2.auditorName}} <span style="color: green">已通过</span></div>
								<div v-if="item2.auditorName !== ''">{{item2.createTime}}</div>
							</div>
							<div v-else-if="item2.checkFlag === 2">
								<div class="steps-info-rt">
									<div>{{item2.auditorName}} <span style="color: red">已驳回</span></div>
									<div><van-icon name="cross" /></div>
								</div>
								<div class="no-message">驳回原因：{{item2.message}}</div>
							</div>
							<div v-else class="steps-info-no">
								<div>{{item2.auditorName}}</div>
							</div>
						</div>
					</van-step>
				</van-steps>
			</div>
		</div>
		<div class="margin-box"></div>
		<div v-if="appStatus !== 3 && appStatus !== 4" class="button-box">
			<div @click="toOk" class="button-ok">通过</div>
			<div @click="toShowNo" class="button-no">驳回</div>
		</div>
		<van-popup
				v-model="show"
				closeable
				lock-scroll
				round
				close-icon="close"
				:style="{ height: '200px', width:'80vw' }"
		>
			<div class="message-box-text">
				<van-field
						v-model="applyMsg"
						rows="2"
						autosize
						type="textarea"
						placeholder="请输入驳回原因"
				/>
			</div>
			<div class="message-box-button">
				<div @click="show = false" class="button-cancel">取消</div>
				<div @click="toNo" class="button-ok">确认</div>
			</div>
		</van-popup>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
	</div>
</template>

<script>
	import { getExchangeInfo, exchangeNo, exchangeOk, getApprovalV2 } from '@/api/service'
	import {Dialog, Tag, Toast} from 'vant';
	import { Empty } from 'vant';
	export default {
	  name: 'DemandList',
	  components: {
		  Empty
	  },
	  data() {
	    return {
			exchangeNo: '', // 单号
			activities: '', // 流程列表
			allInfo: {},
			applyMsg:'',
			rejectRemark: '',
			show: false,
			stepId: '',
			appStatus: 0, // 审批流状态,
	    }},
	  created() {
		  const query = this.$route.query
		  if (!query.exchangeNo) {
			  Dialog.alert({title: '提示', message: '参数错误',})
			  return
		  }
		  this.exchangeNo = query.exchangeNo
		  this.getInfo()
		  this.showApproval()
	  },
	  mounted:function(){
	  },
	  methods: {
		  dropDownClick(e) {
			  console.log(e)
			  this.selectCardId = this.allCardList[e.index].id
		  },
	  	// 获取信息
		  getInfo(){
		  	const postData = {}
			  postData.exchangeNo = this.exchangeNo
			  getExchangeInfo(postData).then((res) => {
				  console.log(res.data)
				  this.allInfo = res.data;
				  // this.showApproval()
			  })
		  },
		  // 查看流程
		  showApproval() {
			  const postData = {}
			  postData.id = this.exchangeNo
			  getApprovalV2(postData).then(response => {
				  this.activities = response.data.list
				  this.appStatus = response.data.status
                  // 判断进行到了哪一步
                  for (let i = 0; i < this.activities.length; i++) {
                  	if(this.activities[i].checkFlag === 1 || this.activities[i].checkFlag === 2){
                  		this.stepId = i
					}
				  }
			  })
		  },
		  // 提交通过
		  toOk(){
		  	let nowStep = ''
			  if (this.stepId === '') {
				  nowStep = 0
			  } else {
				  nowStep = this.stepId + 1
			  }
			  Dialog.confirm({
				  title: '是否确认审批通过',
				  message: '审批通过后此审批流将完结，审批结果不能修改，请谨慎操作！',
			  })
			  .then(() => {
				  // on confirm
				  this.toPass()
			  })
			  .catch(() => {
				  // on cancel
			  });
		  },
		  // 展示拒绝
		  toShowNo(){
			  this.show = true
		  },
		  // 提交拒绝
		  toNo(){
			  this.show = false
			  this.toReject()
		  },
		  // 通过
		  toPass(){
			  const postData = {}
			  postData.exchangeNo = this.exchangeNo
			  exchangeOk(postData).then((response) => {
			  	if(response.code === 200 || response.code === 0){
					this.showApproval()
					Dialog.alert({title: '提示', message: '操作成功',})
				}
			  })
		  },
		  // 驳回
		  toReject() {
		  	if(this.applyMsg === ''){
				Dialog.alert({title: '提示', message: '拒绝理由不能为空',})
				return
			}
			  const postData = {}
			  postData.exchangeNo = this.exchangeNo
			  postData.exchangeMsg = this.applyMsg
			  exchangeNo(postData).then((response) => {
				  if(response.code === 200 || response.code === 0){
					  this.showApproval()
					  Dialog.alert({title: '提示', message: '操作成功',})
				  }
			  })
		  },
	  }
	}
</script>

<style lang="less" scoped>
	.main{
		padding: 20px;
		color:#333333;
	}
	/* 会员列表 - start */
	.member-main{
		margin-bottom: 12px;
		background-color: #ffffff;
		border-radius: 4px;
	}
	.member-title{
		display: flex;
		align-items: center;
		margin: 0 12px;
		padding: 12px 0;
		border-bottom: 1px solid #ECECEC;
	.title-icon{
		width: 3px;
		height: 12px;
		background: #2586FF;
	}
	.title-text{
		font-size: 16px;
		color: #000000;
		font-weight: bold;
		padding-left: 15px;
	}
	}
	.member-box{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		background-color: #ffffff;
	}
	.member-left-box{
		display: flex;
	}
	.member-header{
		width: 40px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #2580F0;
	}
	.member-icon-box{
		display: flex;
		align-items: center;
	}
	.member-info-box{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 40px;
		padding-left: 15px;
	}
	.member-name{
		font-size: 16px;
		font-weight: bold;
		color: #333333;
	}
	.member-tel{
		font-size: 16px;
		color: #666666;
	}
	.member-icon{
		width: 15px;
		height: 15px;
		margin-right: 6px;
	}
	/* 会员列表 - end */
	/* 订单商品 */
	.exchange-box{
		margin: 0 0 12px 0;
		background-color: #ffffff;
		border-radius: 4px;
		padding: 12px;
		font-size: 15px;
	.item-title{
		color: #333333;
		padding: 0 0 12px 0;
		border-bottom: 1px solid #ECECEC;
	}
	.line-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 0 0 0;
	}
	.line-title{
		color: #666666;
	}
	.line-title{
		color: #333333;
	}
	.good{
		border-bottom: 1px solid #F1F1F1;
		padding-bottom: 12px;
	.good-content{
		width: 100%;
		display: flex;
		box-sizing: border-box;
		padding-top: 15px;
	}
	.good-content .good-img{
		width: 70px;
		height: 70px;
		border-radius: 2px;
		margin-right: 10px;
	}
	.good-info{
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.good-title{
		font-size: 16px;
		color: #333;
		font-weight: bold;
		overflow: hidden;
		max-width: 215px;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		margin-bottom: 7px;
	}
	.good-info .good-price{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.good-unit{
		font-size: 14px;
		color: #888888;
	}
	.good-price-value{
		font-size: 15px;
		color: #666666;
	}
	}
	.price-all{
		font-size: 17px;
		color: #2580F0;
		text-align: right;
		padding: 15px 0 3px 0;
	}
	}
	.all-price-box{
		margin: 12px 0 0 0;
		padding: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #ffffff;
		border-radius: 4px;
	.title{
		font-weight: bold;
		font-size: 15px;
		color: #666666;
	}
	.price{
		font-weight: bold;
		font-size: 17px;
		color: #F5A623;
	}
	}


	.order-status{
		margin: 20px 0 0 0;
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.order-status-title{
		display: flex;
		font-weight: bold;
		font-size: 16px;
	}
	.info-box-title-icon{
		width: 5px;
		height: 20px;
		background: #2586FF;
		color: #2586FF;
		margin-right: 10px;
	}
	.button-box{
		width: calc(100vw - 40px);
		position: fixed;
		bottom: 0;
		background-color: #ffffff;
		display: flex;
		justify-content: space-around;
		padding: 20px 0;
	}
	.button-ok{
		background-color: #237FF0;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-no{
		background-color: #FF7833;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.button-cancel{
		background-color: #abadaf;
		color: #ffffff;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		padding: 0 20px;
	}
	.margin-box{
		width: 100%;
		height: 100px;
	}
	.message-box-text{
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	.message-box-button{
		display: flex;
		justify-content: space-around;
		width: 100%;
		margin-top: 30px;
	}
    .steps-info-ok{
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        color: #237FF0;
    }
	.steps-info-rt{
		height: 30px;
		line-height: 30px;
		display: flex;
		justify-content: space-between;
		color: #f83b51;
	}
    .steps-info-no{
        height: 25px;
        line-height: 25px;
    }
	.no-message{
		color: #f83b51;
	}
	.item-line-right-box{}
	.return-res-text{
		padding: 10px 0;
	}
	.popup-top-text{
		width: 100%;
		text-align: center;
		margin-top: 30px;
		font-weight: bold;
	}
</style>
