<template>
  <div class="chat">
    <div class="info-box">
      <van-checkbox-group v-model="radio">
        <div class="select-radio-box" v-for="item in list" :key="item.id">
          <div>
            <van-checkbox :name="item.depid" icon-size="18px"></van-checkbox>
          </div>
          <div class="dep-item-name-box" @click="showNext(item.depid,item.depName)">
            <span>{{item.depName}}</span>
            <van-icon color="#000000" name="arrow" />
          </div>
        </div>
      </van-checkbox-group>
    </div>
    <div class="bottom-margin"></div>
    <div class="bottom-button-box">
      <div @click="toInfo" class="button-left">取消</div>
      <div @click="saveSelect" class="button-right">确认</div>
    </div>
  </div>
</template>

<script>
import { getDepById, sendNotice, getAllDep } from '@/api/live'
import Cookies from 'js-cookie'
import { Toast } from 'vant';
export default {
  name: 'department',
  components: {
  },
  data() {
    return {
      list: [],
      allDepList:[],
      liveId: '',
      id:'',
      userId: '', // 用户id
      nowInfo: {}, // 当前用户信息
      postData: {
        parentId: 1
      },
      depName: '请选择联系人',
      departName: [],
      departId: [],
      type:3,
      radio: [],
      allDep: [],
      lastSelectName: '',
      lastSelectId: ''
    }},
  created() {
    let query=this.$route.query;
    if(!query.liveId || !query.id){
      Toast('参数错误')
      return
    }
    this.liveId = query.liveId
    this.id = query.id
    let userId=Cookies.get('userId')
    if(!userId){
      this.$router.push({ name: 'liveList', query: {}});
      return;
    }else{
      this.getDepList()
      this.getDepAllList()
    }
  },
  mounted:function(){

  },
  methods: {
    // 获取部门信息
    getDepList(){
      getDepById(this.postData).then(response => {
        this.list = response.data
      })
    },
    // 获取部门信息
    getDepAllList(){
      let postData = {}
      postData.type = 1
      getAllDep(postData).then(response => {
        this.allDepList = response.data.children
      })
    },
    // 选择下一级
    showNext(id,name) {
      this.postData.parentId = id
      getDepById(this.postData).then(response => {
        if(response.data.length>0){
          this.departId.push(id)
          this.departName.push(name)
          this.depName = name
          this.list = response.data
        }else{
          Toast('此部门下已无分部门')
          return
        }
      })
    },
    // 返回详情
    toInfo(){
      let postData = {}
      postData.id = this.id
      postData.liveId = this.liveId
      this.$router.push({  path: 'shopShare', query: postData});
    },
    // 把本级及下级元素都放进数组
    putIn(){
      let data = this.allDepList
      this.findChildren(data)
    },
    // 寻找所有部门的下级数组
    findChildren(data){
      for(let i = 0;i<data.length;i++) {
        if(this.radio.indexOf(data[i].id) !== -1){
          this.allDep.push(data[i].id)
          if(data[i].children !== null){
            this.putInAll(data[i].children)
          }
        }
        if (data[i].children !== null) {
          this.findChildren(data[i].children);
        }
      }
    },
    // 把所有id都放进数组
    putInAll(data){
      for (let i = 0;i<data.length;i++) {
        this.allDep.push(data[i].id)
        if (data[i].children !== null) {
          this.putInAll(data[i].children);
        }
      }
    },
    // 确认选择
    saveSelect() {
      const _this = this
      if(this.radio.length <= 0){
        Toast('请至少选择一个部门')
        return
      }
      Toast.loading({message: '加载中...', forbidClick: true, duration: 0});
      // 获取所有部门
      this.putIn()
      let postData = {}
      postData.depIds = this.allDep
      postData.url = 'https://qzone.renkangshangcheng.cn/living/#/shopShare?liveId=' +this.liveId+ '&id=' + this.id
      postData.livingId = this.liveId
      console.log(postData)
      sendNotice(postData).then(response => {
        Toast.clear()
        Toast('发送成功')
        console.log(response)
        let postData = {}
        postData.id = _this.id
        postData.liveId = _this.liveId
        _this.$router.push({  path: 'shopShare', query: postData});
      })
    },
  }
}
</script>

<style scoped>
  .chat{
    background-color:#ffffff;
    padding: 20px;
    min-height: 100vh;
  }
  .info-box{
    margin-top: 0;
  }
  .select-radio-box{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #EBEDF0;
    align-items: center;
    font-size: 15px;
  }
  .bottom-margin{
    height: 100px;
  }
  .dep-item-name-box{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    width: 100%;
  }
  .bottom-button-box{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    background-color: #ffffff;
  }
  .button-left{
    width: 40vw;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #f0f3f3;
    color: #0e0e0e;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #dee0e0;
  }
  .button-right{
    width: 40vw;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: #2586FF;
    color: #ffffff;
    font-size: 16px;
    border-radius: 5px;
  }
</style>
