/*路由设置文件*/

const routers = [
	{
		path: '/',
		name: 'Home',
		meta:{index:1,title: '首页'},
		component: () => import('../views/Home.vue')
	},
    {
        path: '/Home',
        name: 'Home',
        meta:{index:1,title: '首页'},
        component: () => import('../views/Home.vue')
    },
	{
		path: '/examine',
		name: 'Examine',
		meta:{index:1,title: '采购单详情'},
		component: () => import('../views/examine/index.vue')
	},
	{
		path: '/approve',
		name: 'Approve',
		meta:{index:1,title: '提货单详情'},
		component: () => import('../views/examine/approve.vue')
	},
	{
		path: '/price',
		name: 'Price',
		meta:{index:1,title: '商品销售价格审批'},
		component: () => import('../views/examine/price.vue')
	},
	{
		path: '/strategy',
		name: 'Strategy',
		meta:{index:1,title: '市场销售政策审批'},
		component: () => import('../views/examine/strategy.vue')
	},
	{
		path: '/warehouse',
		name: 'Warehouse',
		meta:{index:1,title: '库存调拨详情'},
		component: () => import('../views/warehouse/index.vue')
	},
	{
		path: '/examineList',
		name: 'ExamineList',
		meta:{index:1,title: '通知列表'},
		component: () => import('../views/examine/examineList.vue')
	},
	{
		path: '/shop',
		name: 'Shop',
		meta:{index:1,title: '串店预警'},
		component: () => import('../views/shop/index.vue')
	},
	{
	    path: '/purchase',
	    name: 'Purchase',
	    meta:{index:1,title: '采购意向'},
	    component: () => import('../views/purchase/index.vue')
	},
	{
	    path: '/demand',
	    name: 'DemandList',
	    meta:{index:1,title: '采购意向'},
	    component: () => import('../views/demand/list.vue')
	},
	{
		path: '/demandAdd',
		name: 'demandAdd',
		meta:{index:1,title: '添加采购单'},
		component: () => import('../views/demand/add.vue')
	},
	{
		path: '/demandInfo',
		name: 'demandInfo',
		meta:{index:1,title: '采购单详情'},
		component: () => import('../views/demand/info.vue')
	},
	{
		path: '/card',
		name: 'card',
		meta:{index:1,title: '卡转账详情'},
		component: () => import('../views/card/index.vue')
	},
	{
		path: '/red',
		name: 'red',
		meta:{index:1,title: '红包充值详情'},
		component: () => import('../views/red/index.vue')
	},
	{
		path: '/returnGoods',
		name: 'returnGoods',
		meta:{index:1,title: '退货详情'},
		component: () => import('../views/returnGoods/index.vue')
	},
	{
		path: '/exchangeGoods',
		name: 'exchangeGoods',
		meta:{index:1,title: '换货详情'},
		component: () => import('../views/exchangeGoods/index.vue')
	},
	{
		path: '/share',
		name: 'Share',
		meta:{index:1,title: '直播'},
		component: () => import('../views/live/share.vue')
	},
	{
		path: '/addLive',
		name: 'AddLive',
		meta:{index:1,title: '预约直播'},
		component: () => import('../views/live/addLive.vue')
	},
	{
		path: '/shopShare',
		name: 'ShopShare',
		meta:{index:1,title: '直播详情'},
		component: () => import('../views/live/shopShare.vue')
	},
	{
		path: '/liveList',
		name: 'LiveList',
		meta:{index:1,title: '直播列表'},
		component: () => import('../views/live/liveList.vue')
	},
	{
		path: '/user',
		name: 'User',
		meta:{index:1,title: '直播设置'},
		component: () => import('../views/live/user.vue')
	},
	{
		path: '/historyLive',
		name: 'HistoryLive',
		meta:{index:1,title: '历史记录'},
		component: () => import('../views/live/historyLive.vue')
	},
	{
		path: '/userList',
		name: 'userList',
		meta:{index:1,title: '观看明细'},
		component: () => import('../views/live/userList.vue')
	},
	{
		path: '/redConfig',
		name: 'redConfig',
		meta:{index:1,title: '红包设置'},
		component: () => import('../views/live/redConfig.vue')
	},
	{
		path: '/department',
		name: 'department',
		meta:{index:1,title: '选择部门'},
		component: () => import('../views/live/department.vue')
	},
	{
		path: '/separate',
		name: 'separate',
		meta:{index:1,title: '分账审核'},
		component: () => import('../views/separate/index.vue')
	},

]
export default routers
