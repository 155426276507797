<template>
  <div class="praise">
    <div class="content-box">
      <div class="content-item-box">
        <div class="content-item-title">直播主题</div>
        <div class="content-item-label">
          <van-field v-model="postData.theme" placeholder="请输入直播主题" maxlength="16" input-align="right"/>
        </div>
      </div>
      <div class="content-item-box" @click="showPopup2">
        <div class="content-item-title">开始</div>
        <div class="content-item-label">
          <van-field
                  v-model="postData.reserveStart"
                  placeholder="请选择开始时间"
                  input-align="right"
          />
        </div>
      </div>
      <div class="content-item-box" @click="showPicker = true">
        <div class="content-item-title">时长</div>
        <div class="content-item-label">
          <van-field
                  v-model="pickerName"
                  placeholder="请选择时长"
                  input-align="right"
          />
        </div>
      </div>
      <div class="content-item-box2">
        <div class="content-item-title">封面图</div>
        <div class="upload-box">
          <van-uploader v-model="imgList" multiple :max-count="1" :after-read="afterRead" :before-read="beforeRead" accept="image/jpeg,image/png"/>
        </div>
      </div>
      <div class="content-item-box3">
        <div class="content-item-title">直播简介</div>
        <div class="upload-box">
          <van-field
                  v-model="postData.description"
                  rows="3"
                  autosize
                  type="textarea"
                  maxlength="200"
                  placeholder="直播简介"
                  show-word-limit
          />
        </div>
      </div>
    </div>
    <div class="bottom-margin"></div>
    <div class="bottom-box" @click="saveData">
      <div class="bottom-right">提交</div>
    </div>
    <!--选择时间开始-->
    <van-popup v-model="show2" position="bottom" :style="{ height: '70%' }">
      <van-datetime-picker
              @confirm="confirm2"
              @cancel="cancel2"
              v-model="currentDate2"
              type="datetime"
              title="选择结束时间"
              :min-date="minDate2"
              :max-date="maxDate2"
              :formatter="formatter">
      </van-datetime-picker>
    </van-popup>
    <!--选择时间结束-->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
              show-toolbar
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
import { addLive, getCosToken } from '@/api/live'
import COS from 'cos-js-sdk-v5'
import Cookies from 'js-cookie'
import { Toast } from 'vant';
export default {
  name: 'AddLive',
  components: {
  },
  data() {
    return {
      userId: '', // 用户id
      postData: {
        theme: '',
        reserveStart: '',
        livingDuration: '3600',
        description: '',
        anchorUserid: '',
        img: ''
      },
      showPicker: false,
      pickerName: '1小时',
      imgList:[],
      value2: '',//结束时间显示
      show2: false,//弹出框是否显示
      minDate2: new Date(),//最小时间
      maxDate2: new Date(2030, 10, 1),//最大时间
      currentDate2: new Date(),//结束时间
      num: 0,
      cos: {},
      columns: ['30分钟', '1小时', '2小时', '3小时'],
    }},
  created() {
    const query = this.$route.query
    console.log(query)
    let userId=Cookies.get('userId')
    if(!userId){
      this.$router.push({ name: 'liveList', query: {}});
      return
    }else{
      // 格式化当前时间
      let nowDate = new Date()
      let nowYear = nowDate.getFullYear()
      let nowMonth = (nowDate.getMonth()+1).toString().padStart(2, "0")
      let nowDay = (nowDate.getDate()).toString().padStart(2, "0")
      let nowHours = (nowDate.getHours()+1).toString().padStart(2, "0")
      let newTime = nowYear + '-' + nowMonth + '-' +nowDay + ' ' + nowHours + ':00:00'
      this.postData.reserveStart = newTime
      this.initCOS()
      this.userId=userId
      this.postData.anchorUserid = userId
    }
  },
  mounted:function(){
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      } else if (type === 'hour') {
        return `${val}时`;
      } else if (type === 'minute') {
        return `${val}分`;
      }
      return val;
    },
    //结束时间确定
    confirm2() {
      this.show2 = false;//弹出框隐藏
      //拼接显示时间 年月日时分秒,toString().padStart(2, "0")这个是将1:1:0显示为01:01:00
      this.postData.reserveStart = this.currentDate2.getFullYear() + '-'
              + (this.currentDate2.getMonth() + 1).toString().padStart(2, "0") + '-'
              + this.currentDate2.getDate().toString().padStart(2, "0") + ' '
              + this.currentDate2.getHours().toString().padStart(2, "0") + ':'
              + this.currentDate2.getMinutes().toString().padStart(2, "0") + ':'
              + this.currentDate2.getSeconds().toString().padStart(2, "0")
    },
    //结束时间取消
    cancel2() {
      this.show2 = false;//弹出框隐藏
    },
    //结束时间弹出层
    showPopup2() {
      this.show2 = true;//弹出框显示
    },
    // 监听选择时长
    onConfirm(value) {
      let _this = this
      switch (value) {
        case '30分钟':
          _this.postData.livingDuration = 60 * 30
              break
        case '1小时':
          _this.postData.livingDuration = 60 * 60
          break
        case '2小时':
          _this.postData.livingDuration = 60 * 60 * 2
          break
        case '3小时':
          _this.postData.livingDuration = 60 * 60 * 3
          break
      }
      this.pickerName = value
      this.showPicker = false;
    },
    // 选择图片结束
    beforeRead(file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        Toast('请上传 jpg或png 格式图片');
        return false;
      }
      return true;
    },
    // 监听上传
    afterRead(file) {
      // 将文件上传至服务器
      this.toUpload(1, file)
    },
    // 初始化存储桶
    initCOS() {
      // 初始化实例
      const _this = this
      _this.cos = new COS({
        // 必选参数
        getAuthorization: function(options, callback) {
          console.log(222)
          getCosToken({}).then(response => {
            console.log(response)
            if (response.code === 200 || response.code === 0) {
              //  console.log(json)
              const credentials = response.data
              callback({
                TmpSecretId: credentials.tmpSecretId,
                TmpSecretKey: credentials.tmpSecretKey,
                XCosSecurityToken: credentials.sessionToken,
                // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                StartTime: credentials.startTime, // 时间戳，单位秒，如：1580000000
                ExpiredTime: credentials.expiredTime // 时间戳，单位秒，如：1580000900
                // ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
              })
            }
          })
        }
      })
    },
    // 提交上传
    toUpload(type, file) {
      console.log(file)
      const _this = this
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      this.cos.putObject({
        Bucket: 'tousubiaoyang-1301970825', /* 填入您自己的存储桶，必须字段 */
        Region: 'ap-nanjing', /* 存储桶所在地域，例如ap-beijing，必须字段 */
        Key: 'chartVideo/' + new Date().getTime() + file.file.name, /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
        StorageClass: 'STANDARD',
        Body: file.file, // 上传文件对象
        onProgress: function(progressData) {
          console.log(JSON.stringify(progressData))
        }
      }, function(err, data) {
        Toast.clear()
        console.log(err || data)
        let newUrl = data.Location.replace(/tousubiaoyang-1301970825.cos.ap-nanjing.myqcloud.com/, 'tousubiaoyang-1301970825.file.myqcloud.com')
        newUrl = 'https://' + newUrl
        console.log(newUrl)
        _this.postData.img = newUrl
      })
    },
    // 保存信息
    saveData() {
      Toast.loading({
        message: '保存中...',
        forbidClick: true,
      });
      console.log(this.postData)
      if(this.postData.theme === ''){
        Toast('请先填写直播主题')
        return
      }
      if(this.postData.reserveStart === ''){
        Toast('请先选择时间')
        return
      }
      addLive(this.postData).then(response => {
        console.log(response)
        Toast.clear()
        if(response.code === 0 || response.code === 200){
          this.$router.push({ name: 'LiveList', query: {}});
        }
      })
    },
  }
}
</script>

<style scoped>
  .praise{
    background-color:#ffffff;
    padding: 20px;
    min-height: 100vh;
  }
  .content-box{
    margin-top: 15px;
  }
  .content-item-box{
    width: calc(100vw - 40px);
    padding: 15px 0;
    border-bottom: 1px solid #EBEDF0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .content-item-box2{
    width: calc(100vw - 40px);
    padding: 15px 0;
    border-bottom: 1px solid #EBEDF0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .content-item-box3{
    width: calc(100vw - 40px);
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .content-item-title{
    width: 80px;
    margin-right: 20px;
    color: #333333;
  }
  .content-item-label{
    width: calc(100vw - 110px);
    display: flex;
    justify-content: flex-end;
  }
  .upload-box .van-cell {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px !important;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: #F7F7F7;
  }
  .upload-box{
    margin-top: 20px;
  }
  .bottom-box{
    position: fixed;
    bottom: 60px;
    display: flex;
    justify-content: center;
  }
  .bottom-right{
    width: calc(100vw - 40px);
    height: 40px;
    line-height: 40px;
    background: linear-gradient(136deg, #5DA3FA 0%, #2586FF 100%);
    box-shadow: 3px 3px 8px 0px rgba(48, 51, 57, 0.2);
    border-radius: 2px;
    text-align: center;
    color: #ffffff;
  }
  .bottom-margin{
    height: 100px;
  }
</style>
