import request from '@/utils/request'
// const baseUrl = 'http://salary.renkangshangcheng.cn'
export function getList(data) {
  return request({
    url: '/warehouse/demand/getWarehouseDemands',
    method: 'post',
    data
  })
}

export function getInfo(data) {
  return request({
    url: '/warehouse/demand/getWarehouseDemandInfo',
    method: 'post',
    data
  })
}
// 商品分类接口
export function getGoodsCate(data) {
  return request({
    url: '/backend/goodscate/page',
    method: 'post',
    data
  })
}
// 商品列表
export function goodsList(data) {
  return request({
    url: '/goods/page',
    method: 'post',
	dataType:'json',
    data
  })
}

export function saveDemand(data) {
  return request({
    url: '/warehouse/demand/saveDemand',
    method: 'post',
	dataType: 'json',
    data
  })
}

export function saveWarehouseDemand(data) {
  return request({
    url: '/warehouse/demand/saveWarehouseDemand',
    method: 'post',
	dataType: 'json',
    data
  })
}