import request from '@/utils/request'
// const baseUrl = 'http://salary.renkangshangcheng.cn'
export function getDemandInfoByDemandNo(data) {
  return request({
    url: '/warehouse/demand/getDemandInfoByDemandNo',
    method: 'post',
    data
  })
}
export function saveDemand(data) {
  return request({
    url: '/warehouse/demand/saveDemand',
    method: 'post',
	dataType: 'json',
    data
  })
}
