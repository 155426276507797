<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
	body{
		background-color: #F5F6F7;
	}
	.mainCentent{
	  padding: 20px;
	  color:#333333;
	}
  #app {
    background-color: #F5F6F7;
  }
  .flex{
	  display: flex;
  }
  .frow{
	  flex-direction: row;
  }
  .fcolumn{
	  flex-direction: column;
  }
  .fcenter{
	  justify-content: center;
	  align-items: center;
  }
  .fbetween{
	  justify-content: space-between;
	  align-items: center;
  }
  .faround{
  	  justify-content: space-around;
  	  align-items: center;
  }
  .fend{
	  justify-content: flex-end;
  }
  .mg {
  	margin: 8px 0px;
  }
  .mg20{
  	margin-top: 20px;
  }
  .lmg {
  	margin-left: 5px;
  }
  .c999{
	  color:#999999;
  }
  .c333{
	  color:#333333;
  }
  .cblue{
	  color: #237FF0;
  }
  .corange{
	  color: #FF7833;
  }
</style>
