<template>
	<div class="mainCentent">
		<div class="searchCard">
			  <van-search
			    v-model="search.demandNo"
			    show-action
			    placeholder="请输入采购单号"
			    @clear="handleSearchClear"
			  >
				<template #action>
			      <div class="searchBtn" @click="onSearch">搜索</div>
			    </template>
			  </van-search>
		</div>
		<template  v-if="dataList.length > 0">
			<van-list
			  v-model="loading"
			  :finished="finished"
			  finished-text="没有更多了"

			>

				<div class="goodsCard mg20" v-for="(item,index) in dataList" :key="index" @click="handleInfo(item.demandNo)">
					<div class="flex fcolumn">
						<div class="flex frow fbetween">
							<span>{{item.demandNo}}</span>
							<span>
								<tag color="rgba(35, 127, 240, 0.1)" v-if="item.status==1" text-color="#237FF0" size="large" >待审核</tag>
								<tag color="rgba(0, 168, 104, 0.1)" v-else-if="item.status==2" text-color="#009B60" size="large" >已通过</tag>
								<tag color="rgba(247, 75, 75, 0.1)" v-else text-color="#F74B4B" size="large" >驳回</tag>
							</span>
						</div>
					</div>
					<div class=" mg">
						<div class="flex frow  mg20" v-for="(goods, key) in item.userApplyList" :key="key">
							<div>
								<van-image
								  width="100"
								  height="100"
								  :src="goods.goodsImg"
								/>
							</div>
							<div class="flex fcolumn fbetween lmg goodsitem">
								<div>{{goods.goodsName}}</div>
								<div class="num corange">{{goods.goodsNum}}</div>
							</div>
						</div>
					</div>
					<div class="mg20 flex fend c999">
						<div >{{item.createTime}}</div>
					</div>
				</div>
			</van-list>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>

		<div class="addbtn">
			<van-icon name="add" color="#237FF0" @click="handleToAdd" size="60"/>
		</div>
	</div>
</template>

<script>
	import {getList} from '@/api/demand'
	import { Tag } from 'vant';
	import { Empty } from 'vant';
	import { List } from 'vant'
	export default {
	  name: 'DemandList',
	  components: {
		  Tag,
		  Empty,
		  VanList: List,
	  },
	  data() {
	    return {
			search:{
				demandNo: '',	//需求单号
				page: 1,
				pageSize: 10
			},
			dataList: [],	//数据列表
			loading: false,	//是否加载中
			finished: false, //是否加载完成
	    }},
	  created() {
	  },
	  mounted:function(){
		  this.getList();
	  },
	  methods: {
		  getList(){
			  this.loading = true;
			  getList(this.search).then((res) => {
				  console.log(res)
				  this.dataList = this.dataList.concat(res.data.list);
				  console.log(this.dataList)
				  if (res.data.list.length < this.search.pageSize){
					  // 没有有下一页
					  this.finished = true;
				  }
				  this.loading = false;
			  })
		  },
		  // 搜索
		  onSearch(){
			  this.dataList = [];
			  this.search.page = 1;
			  this.getList();
		  },
		  // 搜索框清空
		  handleSearchClear(){
			  this.search.demandNo = "";
		  },
		  handleInfo(demandNo){
			  this.$router.push({path: '/demandInfo', query: { demandNo: demandNo} })
		  },
		  handleToAdd(){
			  this.$router.push({path: '/demandAdd'})
		  }
	  }
	}
</script>

<style>
	.searchCard{
		border-radius: 12px;
	}
	.goodsCard{
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.goodsitem{
		width: -webkit-fill-available;
		align-items: flex-start;
	}
	.num{
		text-align: right;
		width: inherit;
		font-size: 22px;
	}
	.searchBtn{
		background-color: #237FF0;
		border-radius: 8px;
		color: #FFFFFF;
		width:50px;
		text-align: center;
		font-size: 16px;
	}
	.addbtn{
		position: fixed;
		right: 20px;
		bottom: 100px;

	}
</style>
