<template>
    <div class="vue-dropdown">
        <div class="cur-name" @click="isShow =! isShow">{{current}}</div>
        <div class="list-and-search" :class="isShow?'on':''">
            <div class="search-module clearfix" v-show="isNeedSearch">
                <input class="search-text" @input='search' :placeholder="placeholder" />
            </div>
            <ul class="list-module">
                <li v-for ="(item,index) in datalist" @click="clickItem(item,index)" :key="index">
                    <span class="list-item-text">{{item}}</span>
                </li>
            </ul>
            <div class="tip-nodata" v-show="searchValue.length && !datalist.length">未找到数据</div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                searchValue: '',
                current: this.curData,
                datalist:[],
                isShow:false
            }
        },
        props:{
            curData:String,	// 当前选中数据
            itemData:Array,	// 所有选项数据
            isNeedSearch:{	// 是否需要搜索
                type: Boolean,
                default: true
            },
            placeholder:{	// 输入框提示文本
                type: String,
                default: '输入卡名称搜索'
            },
        },
        created(){
            this.datalist = this.itemData;
            //点击组件以外的地方，收起
            // document.addEventListener('click', (e) => {
            // 	if (!this.$el.contains(e.target)){
            // 		this.isShow = false;
            // 	}
            // }, false)
        },
        methods:{
            search(e){
                this.searchValue = e.target.value;
                this.datalist = this.itemData.filter((item)=>{
                    return item.indexOf(this.searchValue) != -1;
                });
            },
            clickItem(item,index){
                this.current = item;
                this.isShow = false;
                let returnData = {}
                returnData.item = item
                returnData.index = index
                this.$emit('change',returnData);
            },
        }
    }
</script>

<style scoped>
    .vue-dropdown {
        z-index: 10;
        width: 40vw;
        border-radius: 3px;
        border: 1px solid #ccc;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        position: relative;
    }
    .vue-dropdown._self-show {
        display: block !important;
    }
    .vue-dropdown .search-module {
        position: relative;
        border-bottom: 1px solid #ccc;
    }
    .vue-dropdown .search-module .search-text {
        width: 100%;
        height: 30px;
        padding: 0 10px;
        box-shadow: none;
        outline: none;
        border: none;
    }
    .vue-dropdown .search-module .search-icon {
        position: absolute;
        top: 24%;
        right: 0.5em;
        color: #aaa;
    }
    .vue-dropdown input::-webkit-input-placeholder {
        font-size: 14px;
    }
    .vue-dropdown .list-module {
        max-height: 200px;
        overflow-y: auto;
    }
    .vue-dropdown .list-module li {
        padding: 10px;
    }
    .vue-dropdown .list-module li._self-hide {
        display: none;
    }
    .vue-dropdown .list-module li:hover {
        cursor: pointer;
        color: #fff;
        background: #00a0e9;
    }

    .list-and-search {
        display: none;
        background: #fff;
        border: 1px solid #ccc;
        position: absolute;
        width: 100%;
    }
    .list-and-search.on {
        display: block;
    }

    .cur-name {
        height: 32px;
        line-height: 32px;
        padding: 0 10px;
        position: relative;
        color: #777;
    }
    .cur-name:after {
        position: absolute;
        right: 9px;
        top: 13px;
        content: " ";
        width: 0;
        height: 0;
        border-right: 6px solid transparent;
        border-top: 6px solid #7b7b7b;
        border-left: 6px solid transparent;
        border-bottom: 6px solid transparent;
    }
    .cur-name.show:after {
        right: 9px;
        top: 6px;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #7b7b7b;
        border-left: 6px solid transparent;
        border-top: 6px solid transparent;
    }

    .tip-nodata {
        font-size: 14px;
        padding: 10px;
    }
</style>
