<template>
    <div class="main">
        <div class="live-list-box">
            <div v-for="(item,index) in list" :key="index" class="live-box-main">
                <div @click="toList(item)" class="live-box-right">
                    <div class="list-top-box">
                        <div class="live-list-title live-line">{{item.theme}}</div>
                        <div v-if="item.status === 0" style="font-size: 14px;color: #4ffa52">预约中</div>
                        <div v-else-if="item.status === 1" style="font-size: 14px;color: #5a5af5">直播中</div>
                        <div v-else-if="item.status === 2" style="font-size: 14px;color: #999a9a">已结束</div>
                        <div v-else-if="item.status === 3" style="font-size: 14px;color: #ece06d">已过期</div>
                        <div v-else-if="item.status === 4" style="font-size: 14px;color: #fa2c5c">已取消</div>
                    </div>
                    <div class="live-list-time live-line">{{item.livingStart}} - {{item.livingEnd}}</div>
                    <div class="live-list-name live-line">直播人：{{item.userName}}</div>
                    <div class="live-list-name live-line">直播时长：{{item.livingDuration || '-'}}</div>
                    <div class="live-list-name live-line">简介：{{item.description || '-'}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getHistory } from '@/api/live'
    import Cookies from "js-cookie";
    // import {Toast} from "vant";
    export default {
        name: 'LiveList',
        data() {
            return {
                userId: '',
                list: '',
                postData: {
                    userId:'',
                    page:1,
                    pageSize: 20
                }
            }},
        created() {
            let userId=Cookies.get('userId')
            if(!userId){
                this.$router.push({ name: 'liveList', query: {}});
                return
            }else{
                this.postData.userId=userId
                this.getAllLive()
            }
        },
        mounted:function(){
        },
        methods: {
            // 获取列表
            getAllLive(){
                getHistory(this.postData).then(response => {
                    console.log(response)
                    this.list = response.data
                })
            },
            // 跳转到分享页面
            toList(item){
                let postData = {}
                postData.id = item.id
                postData.liveId = item.livingId
                this.$router.push({  path: 'userList', query: postData});
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100%;
        min-height: 100vh;
        position: relative;
        background-color: #ffffff;
    }
    .live-list-box{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px;
    }
    .live-box-main{
        display: flex;
        background-color: #f6f6f6;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 15px;
    }
    .list-top-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .live-box-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100vw - 60px);
        padding: 0 10px;
    }
    .live-list-title{
        font-weight: bold;
        font-size: 16px;
        width: calc(100vw - 160px);
        display:-webkit-box;/**对象作为伸缩盒子模型展示**/
        -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp:1;/**显示的行数**/
        overflow:hidden;/**隐藏超出的内容**/
    }
    .live-line{
        height: 30px;
        line-height: 30px;
    }
    .live-list-time{
        font-size: 14px;
    }
    .live-list-name{
        font-size: 14px;
        color: #999a9a;
    }
</style>
