<template>
	<div class="mainCentent">
		<div class="txt1">申请人：{{userName}}</div>
		<!--  展示已选择的商品 -->
		<div>
			<div class="order-card" v-if="checkGoods.length > 0">
				<div class="flex frow fbetween demandNo mg">
					<span></span>
					<span>
						<a href="javascript:void(0);" @click="showGoodsPop=true">添加商品</a>
					</span>
				</div>

				<div class="flex fcolumn mg" v-for="(goods, index) in checkGoods" :key="index">
					<div class="flex frow mg">
						<div>
							<van-image width="100" height="100" :src="goods.goodsImg" />
						</div>
						<div class="flex fcolumn mg lmg titleCenter">
							<div>{{goods.goodsName}}</div>
							<div class="flex frow fbetween mg">
								<span class="unit">单位：{{goods.goodsUnit}}</span>
								<span>
									<van-icon name="delete" @click="handleDelCheck(index)" color="#ee0a24" size="25" />
								</span>
							</div>
						</div>
					</div>
					<div class="flex fbetween mg">
						<div>采购数量</div>
						<div class="flex frow fcenter">
							<div class="iptbtn lb" @click="updateBuyNum(index, '-')">
								<van-icon name="minus" />
							</div>
							<van-field type="digit" maxlength="5" v-model="goods.buyNum" class="ipt" />
							<div class="iptbtn rb" @click="updateBuyNum(index, '+')">
								<van-icon name="plus" />
							</div>
						</div>
					</div>
				</div>
				<div class="flex fcolumn addbox">
					<div class="flex frow fbetween">
						<span>总采购数量</span>
						<span>{{totalBuyNum}}</span>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 100vw; height: 120px"></div>
		<div class="flex fcenter bottom " v-if="checkGoods.length == 0">
			<Button type="info" block class="mybtn"  @click="handleShowGoods">添加商品</Button>
		</div>
		<div class="flex frow faround goodsBottom " v-else>
			<div style="width:48%">
				<van-button color="#E9F2FE" class="bot-btn-small" :style="{color:'#237FF0'}" @click="handleCancelAdd" >取消</van-button>
			</div>
			<div style="width:48%">
				<van-button color="#237FF0" class="bot-btn-small" @click="handleSubmit" >确定</van-button>
			</div>
		</div>
		<popup v-model="showGoodsPop" position="right" :style="{ height: '100%',width:'100%'}">

			<div class="goodsPop">
				<div class="searchCard">
						<van-search
							v-model="goodsData.goodsName"
							show-action
							placeholder="请输入采购单号"
							@clear="handleSearchClear"
						>
							<template #action>
								<div class="searchBtn" @click="onSearch">搜索</div>
							</template>
						</van-search>
				</div>
				<div class="mg20">
					<div class="cate-box">
						<dropdown-menu class="cate" >
							<dropdown-item v-model="goodsData.cateId" :options="cateList" @change="handleCateChange" />
						</dropdown-menu>
					</div>
					<div style="padding:15px;">
						<van-list
						  v-model="loading"
						  :finished="finished"
						  finished-text="没有更多了"
						>
							<checkbox-group v-model="checked" >
								<div class="flex frow mg20" style="width: -webkit-fill-available;" v-for="(goods, index) in goodsLists" :key="index">
									<div>
										<van-image
										  width="100"
										  height="100"
										  :src="goods.goodsImg"
										/>
									</div>
									<div class="flex fcolumn fbetween lmg" style="align-items: flex-start;width: inherit;">
										<div>
											<div>{{goods.goodsName}}</div>
											<div class=" mg">
												<span class="unit">单位：{{goods.unitName}}</span>

											</div>
										</div>

										<div class="goodsRedio">
											<checkbox :name="index" ></checkbox>
										</div>
									</div>
								</div>

							</checkbox-group>
						</van-list>
					</div>
				</div>
				<div style="width: 100vw;height: 100px"></div>
				<div class="flex frow faround goodsSelectBottom">
					<div style="width:40%">
						<van-button color="#E9F2FE" class="bot-btn-small" :style="{color:'#237FF0'}" @click="showGoodsPop=false" >取消</van-button>
					</div>
					<div style="width:40%">
						<van-button color="#237FF0" class="bot-btn-small" @click="handleCheckButton" >确定</van-button>
					</div>
				</div>
			</div>
		</popup>
	</div>
</template>

<script>
	import { Button,Popup,DropdownMenu, DropdownItem,Checkbox,CheckboxGroup,List } from 'vant';
	// import { ref } from 'vue';
	import {getGoodsCate, goodsList,saveWarehouseDemand} from '@/api/demand'
	export default {
	  name: 'demandAdd',
	  components: {
		  Button,
		  Popup,
		  DropdownMenu,
		  DropdownItem,
		  Checkbox,
		  CheckboxGroup,
		  VanList: List,
	  },
	  data() {

	    return {
			userName: "",
			showGoodsPop: false, //是否打开商品列表pop页
			loading: false,	//是否加载中
			finished: false, //是否加载完成
			search:{
				demandNo: '',	//需求单号
				page: 1,
				pageSize: 10
			},

			cateList:[{text:"商品分类", value:0}],
			goodsData:{			// 商品列表条件
				goodsName:"",
				cateId: 0,
				page:1,
				pageSize: 20
			},
			goodsLists: [],			// 商品列表
			checked: [],			//商品多选按钮列表
			checkGoods: [], 		//已选择商品列表
			submitData:{			//提交数据
				demandNo: "",		//需求单号
				demandGoodsList:[]	//需求商品列表
			}
	    }},
		computed:{
			//计算属性：总裁购书
			totalBuyNum(){
				let num = 0;
				if (this.checkGoods.length > 0){

					for(let i = 0; i<this.checkGoods.length; i++){
						num = parseInt(num) + parseInt(this.checkGoods[i].buyNum)
					}
				}
				return num;
			}
		},

	  mounted:function(){
		  this.userName = sessionStorage.getItem("username");
		 this.handleGoodsCate();
	  },
	  methods: {
		  // 清空商品列表搜索框
		  handleSearchClear(){
			  this.goodsData.goodsName = "";
		  },
		  // 商品列表顶部搜索框确定按钮
		  onSearch(){
			  this.goodsData.page = 1;
			  this.goodsLists = [];
			  this.getGoodsList();
		  },
		  //取消添加按钮
		  handleCancelAdd(){
			  this.$router.push({path: '/demand'})
		  },
		  // 打开选择商品
		  handleShowGoods(){
			  this.showGoodsPop = true;
			  this.checked = [];
			  this.getGoodsList();
		  },
		  // 获取商品分类
		  handleGoodsCate(){
			  getGoodsCate({page:1,pageSize:100}).then((res) => {

				  if (res.data.cateList.length > 0){
					  for (let id in res.data.cateList) {

					  	let item = {
							  text: res.data.cateList[id].cateName,
							  value: res.data.cateList[id].id
					  	}
					  	this.cateList.push(item)
					  }
				  }
			  })
		  },
		  // 获取商品列表
		  getGoodsList(){

			  goodsList(this.goodsData).then((res) => {

				  this.goodsLists = res.data.goodsList;
				  if (res.data.goodsList.length < this.goodsData.pageSize){
				  		// 没有有下一页
				  		this.finished = true;
				  }
			  })
		  },

		  // 选择商品确定按钮事件
		  handleCheckButton(){
			  // this.checkGoods = [];
			  for(let i = 0; i< this.checked.length; i++){
				 let goodsItem = this.goodsLists[this.checked[i]];
				 let goods = {
					"buyNum": 0,
					"goodsImg": goodsItem.goodsImg,
					"goodsName": goodsItem.goodsName,
					"goodsNo": goodsItem.goodsNo,
					"goodsUnit": goodsItem.unitName
				 }
				 let haveGoods = this.isHave(goodsItem.goodsNo)
			     if(haveGoods === false){
					 this.checkGoods.push(goods);
				  }
			  }
			  this.showGoodsPop = false;
			  // 调用接口生成需求单号

		  },
		  // 判断选择商品是否已添加
		  isHave(goodsNo){
		  	let oldGoods = false
			  for(let i = 0; i< this.checkGoods.length; i++){
			  	if(this.checkGoods[i].goodsNo === goodsNo){
					oldGoods = true
				}
			  }
			  return oldGoods
		  },
		  // 删除已选择的商品
		  handleDelCheck(index){
			  this.checkGoods.splice(index, 1)
			  this.checked.splice(index, 1)
		  },
		  // 加减采购数量
		updateBuyNum(index, type) {
			let goods = JSON.parse(JSON.stringify(this.checkGoods[index]));
			if (type == "+"){
				goods.buyNum++;
			} else {
				if (goods.buyNum > 0){
					goods.buyNum--;
				} else {
					this.$dialog({
						message:"数量不能小于0"
					});
					return;
				}
			}
			this.checkGoods.splice(index, 1, goods);
		},
		  // 提交
		handleSubmit() {
			this.$dialog.confirm({
				confirmButtonColor:"#237FF0",
				cancelButtonColor: "#333333",
			    message: '确认提交吗？提交后不可修改',
			})
			 .then(() => {
			    // on confirm、
				this.submitData.demandNo="66666666"; //无效
				this.submitData.demandGoodsList = this.checkGoods;

				saveWarehouseDemand(this.submitData).then((res) => {
					console.log(res)
					this.$router.push({path: '/demand'})
				})
			 })
			 .catch(() => {
			    // on cancel
				console.log("取消")
			 });


		},
		//商品分类选择事件
		handleCateChange(){
			this.goodsData.page = 1;
			this.goodsLists = [];
			this.getGoodsList();
		}
	  }
	}
</script>

<style>
	.bottom{
		position: fixed;
		bottom: 0;
		width: 96%;
		height: 100px;
		margin:0 -20px;
		padding: 0 2%;
	}
	.mybtn{
		border-radius: 8px;
	}
	.goodsBottom{
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 100px;
		background-color: #ffffff;
		margin:0 -20px;
	}
	.goodsSelectBottom{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 100px;
		background-color: #ffffff;
	}
	.bot-btn{
		width:100%;
		border-radius: 12px;
		height: 60px;
		font-size: 22px;
	}
	.bot-btn-small{
		width: 100%;
		border-radius: 12px;
		font-size: 18px;
	}
	.cate{
		justify-content: flex-start;
		display: flex;
		padding-left: 15px;
	}
	.cate-box{
		width: 130px;
		background-color: #ffffff;
	}
	.goodsRedio{
		width: inherit;
		display: flex;
		justify-content: flex-end;
	}
	.order-card {
		margin-top: 15px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 10px;
	}

	.demandNo {
		color: #99999;
	}

	.bottom-button{
		position: fixed;
		bottom: 0;
		display: flex;
		justify-content: space-between;
	}

	.ipt {
		width: 60px !important;
		height: 32px !important;
		border: 1px solid #98999A;
		line-height: 32px;
		padding: 0;
	}
	.van-dropdown-menu__bar{
		width: 200px !important;
	}

	.van-field__control {
		text-align: center;
	}

	.iptbtn {
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		font-size: 20px;
		border-top: 1px solid #98999A;
		border-bottom: 1px solid #98999A;
	}
	.lb {
		border-left: 1px solid #98999A;
	}

	.rb {
		border-right: 1px solid #98999A;
	}

	.unit {
		padding: 5px 8px;
		background: #F5F6F7;
		border-radius: 4px;
		color: #999999;
	}
	.goodsPop{
		position: relative;
	}

	.titleCenter {
		width: -webkit-fill-available;
	}
</style>
