<template>
	<div v-if="list.length > 0">
		<div class="order-card" v-for="(item,index) in list" :key="index">
			<div class="flex frow fbetween demandNo mg">
				<span>{{item.demandNo}}	</span>
				<span>
					<van-icon name="delete"  @click="handleDelItem(index)" color="#ee0a24" size="25" />
				</span>
			</div>
			<div class="flex fbetween mg20">
				<div>所属城市</div>
				<div class="flex frow fcenter" @click="handleCheckCity(index)">
					<span v-if="item.cityName != ''">{{item.cityName}}</span>
					<span v-else>选择城市</span>
					<span>
						<van-icon name="arrow" />
					</span>
				</div>
			</div>
			<div class="flex fcolumn mg" v-for="(goods, key) in item.demandGoodsList" :key="key">
				<div class="flex frow mg">
					<div>
						<van-image width="100" height="100" :src="goods.goodsImg" />
					</div>
					<div class="flex fcolumn mg lmg titleCenter">
						<div>{{goods.goodsName}}</div>
						<div class=" mg">
							<span class="unit">单位：{{goods.goodsUnit}}</span>
							
						</div>
					</div>
				</div>
				<div class="flex fbetween mg">
					<div>采购数量</div>
					<div class="flex frow fcenter">
						<div class="iptbtn lb" @click="updateBuyNum(index, key, '-')">
							<van-icon name="minus" />
						</div>
						<van-field type="digit" v-model="goods.buyNum" class="ipt" />
						<div class="iptbtn rb" @click="updateBuyNum(index, key, '+')">
							<van-icon name="plus" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<myCity :show="showArea" @confirm="handleCityConfirm" @cancel="handelCancel"></myCity>
	</div>
</template>

<script>
	import myCity from '@/components/AreaModal.vue'
	export default {
		name: 'PurchaseList',
		props: {
			list: {
				type: Array,
				default: function() {
					return []
				}
			}
		},
		components: {
			myCity
		},
		data() {
			return {
				showArea: false, // 是否显示城市选择
				checkCityIndex: 0, // 选择城市对应的list下标
				demandList: []
			}
		},
		created() {},
		mounted: function() {},

		methods: {
			handleCheckCity(index) {
				this.showArea = true;
				this.checkCityIndex = index;
			},
			//取消选择城市事件
			handelCancel() {
				this.showArea = false;
			},
			//选择城市确定事件
			handleCityConfirm(res) {
				this.showArea = false;

				var ret = {
					index: this.checkCityIndex,
				 data: res
				}
				console.log(ret)
				this.$emit("updateCity", ret);
			},
			// 加减采购数量
			updateBuyNum(index, key, type) {
				this.$emit("updateBuyNum", {
					index: index,
					goodsIndex: key,
					type: type
				})
			},
		 // 删除一项需求单
			handleDelItem(index) {
				this.$emit("delItem", {
					index: index
				})
			}
		}
	}
</script>

<style>
	.order-card {
		margin-top: 15px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 10px;
	}

	.demandNo {
		color: #99999;
	}

	.ipt {
		width: 60px !important;
		height: 32px !important;
		border: 1px solid #98999A;
		line-height: 32px;
		padding: 0;
	}

	.van-field__control {
		text-align: center;
	}

	.iptbtn {
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		font-size: 20px;
		border-top: 1px solid #98999A;
		border-bottom: 1px solid #98999A;
	}

	.lb {
		border-left: 1px solid #98999A;
	}

	.rb {
		border-right: 1px solid #98999A;
	}

	.unit {
		padding: 5px 8px;
		background: #F5F6F7;
		border-radius: 4px;
		color: #999999;
	}

	.titleCenter {
		width: -webkit-fill-available;
	}
</style>
