<template>
	<div class="mainCentent">
		<div class="txt1">申请人：{{demandInfo.userName}}</div>
		<div class="goodsCard mg20" >
			<div class="flex fcolumn">
				<div class="flex frow fbetween">
					<span>{{demandInfo.demandNo}}</span>
					<span>
						<tag color="rgba(35, 127, 240, 0.1)" v-if="demandInfo.status==1" text-color="#237FF0" size="large" >待审核</tag>
						<tag color="rgba(0, 168, 104, 0.1)" v-else-if="demandInfo.status==2" text-color="#009B60" size="large" >已通过</tag>
						<tag color="rgba(247, 75, 75, 0.1)" v-else text-color="#F74B4B" size="large" >驳回</tag>
					</span>
				</div>
			</div>
			<div class=" mg">
				<div v-for="(goods, key) in demandInfo.userApplyList" :key="key">
					<div class="flex frow  mg20" >
						<div>
							<van-image
							  width="100"
							  height="100"
							  :src="goods.goodsImg"
							/>
						</div>
						<div class="flex fcolumn lmg goodsitem">
							<div>{{goods.goodsName}}</div>
							<div class=" mg">
								<span class="unit">单位：{{goods.goodsUnit}}</span>

							</div>
						</div>
					</div>
					<div class="flex frow fbetween">
						<div>采购数量</div>
						<div>x {{goods.goodsNum}}</div>
					</div>
				</div>
			</div>
			<div class="mg20">
				<div class="flex frow fbetween">
					<div>总采购数量</div>
					<div>{{totalBuyNum}}</div>
				</div>
			</div>
		</div>

		<div class="goodsCard mg20">
			<p>订单状态</p>
			<steps direction="vertical" :active="0">
			  <step>
			    <h3>申请人：{{demandInfo.userName}}</h3>
			    <p>{{demandInfo.createTime}}</p>
			  </step>
			  <step v-if="demandInfo.status > 1">
			    <h3>审核人：{{demandInfo.approveUsername}}</h3>
			    <p>
					<tag color="rgba(0, 168, 104, 0.1)" v-if="demandInfo.status==2" text-color="#009B60" size="large" >已通过</tag>
					<tag color="rgba(247, 75, 75, 0.1)" v-else text-color="#F74B4B" size="large" >驳回</tag>
				</p>
			    <p v-if="demandInfo.status === 3">驳回原因：{{demandInfo.rejectMsg}}</p>
			    <p>{{demandInfo.approveTime}}</p>
			  </step>
			</steps>
		</div>
		<div class="goodsCard mg20">
			<p>申请人信息</p>
			<div class="flex frow fbetween mg">
				<span>申请人</span>
				<span>{{demandInfo.userName}}</span>
			</div>
			<div class="flex frow fbetween mg">
				<span>所属门店</span>
				<span>{{demandInfo.shopName}}</span>
			</div>
			<div class="flex frow fbetween mg">
				<span>所属团队</span>
				<span>{{demandInfo.teamName}}</span>
			</div>
			<div class="flex frow fbetween mg">
				<span>提交时间</span>
				<span>{{demandInfo.createTime}}</span>
			</div>
		</div>
		<div class="goodsCard mg20">
			<Button type="info" block class="mybtn" @click="handleBack">返 回</Button>
		</div>
	</div>
</template>

<script>
	import {getInfo} from '@/api/demand'
	import { Tag, Step, Steps,Button } from 'vant';
	export default {
	  name: 'demandInfo',
	  components: {
		  Tag,
		  Step,
		  Steps,
		  Button
	  },
	  data() {
	    return {
			demandNo: "", //需求单号
			demandInfo: {}, //需求单详情对象
	    }},
	  created() {
		  if (this.$route.query.demandNo != undefined && this.$route.query.demandNo != ""){
			  this.demandNo = this.$route.query.demandNo;
			  this.info();
		  }
	  },
	  computed:{
	  	//计算属性：总裁购书
	  	totalBuyNum(){
	  		let num = 0;
	  		if (this.demandInfo.demandNo != undefined){
	  			console.log(this.demandInfo.userApplyList)
	  			for(let i = 0; i<this.demandInfo.userApplyList.length; i++){


	  				num = parseInt(num) + parseInt(this.demandInfo.userApplyList[i].goodsNum)

	  			}
	  		}
	  		return num;
	  	}
	  },
	  mounted:function(){
	  },
	  methods: {
		  info(){
			  getInfo({demandNo: this.demandNo}).then((res) => {

				  console.log(res);
				  this.demandInfo = res.data;
			  })
		  },
		  handleBack(){
			  this.$router.back();
		  }
	  }
	}
</script>

<style>
	.txt1{
		color: rgba(51, 51, 51, 1);
		font-size: 20px;
	}
	.goodsCard{
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.goodsitem{
		width: -webkit-fill-available;
		align-items: flex-start;
	}
	.num{
		text-align: right;
		width: inherit;
		font-size: 22px;
	}
	.mybtn{
		border-radius: 8px;
	}
</style>
